import { DatePipe, KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Hotel } from '../../../../services/andromeda.model';

@Component({
  selector: 'app-andromeda-claim-hotels-combi',
  templateUrl: './andromeda-claim-hotels-combi.component.html',
  styleUrl: './andromeda-claim-hotels-combi.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [KeyValuePipe, ReactiveFormsModule, DatePipe],
})
export class AndromedaClaimHotelsCombiComponent {
  form = input.required<FormGroup>();
  hotelGroups = input.required<any>({});

  formatMarkup(hotel: Hotel): string {
    return Number(hotel.supplement) > 0
      ? ` +${hotel.supplement} ${hotel.currencyAlias}`
      : ` -${hotel.supplement} ${hotel.currencyAlias}`;
  }
}
