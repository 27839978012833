import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-hotel-sales-count',
  template: `Купили: {{ salesCount }}`,
  styles: [
    `
      :host {
        display: block;
        padding: 2px 8px;
        background-color: #e1e9f5;
        font-size: 10px;
        line-height: 12px;
        border-radius: 200px;
        min-width: 75px;
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchHotelSaleCountComponent {
  @Input() salesCount!: number;
}
