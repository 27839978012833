import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PostFilterPrices } from '../../../search-tours.model';
import { SearchHotelTagsService } from '../../../services/search-hotel-tags.service';

@Injectable()
export class SearchResultFiltersService {
  private hasFiltersSub = new BehaviorSubject<boolean>(false);
  private defaultPricesValue = { from: undefined, to: undefined };
  private filterHotelStarsListSub = new BehaviorSubject<string[]>([]);
  private filterHotelRegionsListSub = new BehaviorSubject<string[]>([]);
  private filterHotelRatingListSub = new BehaviorSubject<number[]>([]);
  private filterPricesSub = new BehaviorSubject<PostFilterPrices>(this.defaultPricesValue);
  private filterTagIdsSub = new BehaviorSubject<number[]>([]);
  private filterOnlyWithPriceSub = new BehaviorSubject<boolean>(true);

  filterHotelStarsList$ = this.filterHotelStarsListSub.asObservable();
  filterHotelRegionsList$ = this.filterHotelRegionsListSub.asObservable();
  filterHotelRatingList$ = this.filterHotelRatingListSub.asObservable();
  filterPrices$ = this.filterPricesSub.asObservable();
  filterTagIds$ = this.filterTagIdsSub.asObservable();
  filterOnlyWithPrice$ = this.filterOnlyWithPriceSub.asObservable();
  hasFilters$ = this.hasFiltersSub.asObservable();

  constructor(private readonly searchHotelTagsService: SearchHotelTagsService) {}

  flushFilters(): void {
    this.filterHotelStarsListSub.next([]);
    this.filterHotelRatingListSub.next([]);
    this.filterTagIdsSub.next([]);
    this.filterPricesSub.next(this.defaultPricesValue);
    this.hasFiltersSub.next(false);
    this.searchHotelTagsService.flushTagsByHotels();
  }

  applyHotelRegionsFilter(value: string[]): void {
    this.filterHotelRegionsListSub.next(value);
    this.calcHasFilters();
  }

  applyHotelStarsFilter(value: string[]): void {
    this.filterHotelStarsListSub.next(value);
    this.calcHasFilters();
  }

  applyHotelRatingFilter(value: number[]): void {
    this.filterHotelRatingListSub.next(value);
    this.calcHasFilters();
  }

  applyPricesFilter(value: PostFilterPrices): void {
    this.filterPricesSub.next(value);
    this.calcHasFilters();
  }

  applyTags(value: number[]) {
    this.filterTagIdsSub.next(value);
    this.calcHasFilters();
  }

  applyOnlyWithPriceFilter(value: boolean): void {
    this.filterOnlyWithPriceSub.next(value);
  }

  private calcHasFilters(): void {
    const hasFilters =
      !!this.filterTagIdsSub.value.length ||
      !!this.filterHotelRatingListSub.value.length ||
      !!this.filterHotelStarsListSub.value.length ||
      !!this.filterPricesSub.value.to ||
      !!this.filterPricesSub.value.from ||
      this.filterOnlyWithPriceSub.value;

    this.hasFiltersSub.next(hasFilters);
  }
}
