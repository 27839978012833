import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../../../environments/environment';
import { Worker } from '../../../../../models';
import { SearchResultsResponseTour } from '../../../services/search/websocket-tours.model';
import {
  BaseRequestParams,
  CalculateClaimParamsRequest,
  ChangeFlightClaimParamsRequest,
  ChangeHotelClaimParamsRequest,
  ChangeServiceClaimParamsRequest,
  ToggleInfantClaimParamsRequest,
  ViewClaimParamsRequest,
  ViewClaimResponse,
  ViewGDSClaimParamsResponse,
} from './andromeda.model';

@Injectable()
export class AndromedaApiService {
  constructor(private readonly http: HttpClient) {}

  loadClaim(worker: Worker, tour: SearchResultsResponseTour): Observable<ViewClaimResponse> {
    const uri = `${AppConfig.andromedaApiUrl}/search/view`;

    const request: ViewClaimParamsRequest = {
      ...this.getBaseRequestParams(worker, tour),
      currency: tour.brandPrice.currency,
    };

    return this.http.post<ViewClaimResponse>(uri, request);
  }

  loadGdsClaim(worker: Worker, tour: SearchResultsResponseTour): Observable<ViewClaimResponse> {
    const uri = `${AppConfig.andromedaApiUrl}/search/view-gds`;

    const request: ViewGDSClaimParamsResponse = {
      ...this.getBaseRequestParams(worker, tour),
      currency: tour.brandPrice.currency,
    };

    return this.http.post<ViewClaimResponse>(uri, request);
  }

  calculateClaim(
    worker: Worker,
    tour: SearchResultsResponseTour,
    rawClaim: any,
  ): Observable<ViewClaimResponse> {
    const uri = `${AppConfig.andromedaApiUrl}/search/calculate`;

    const request: CalculateClaimParamsRequest = {
      ...this.getBaseRequestParams(worker, tour),
      rawClaim,
    };

    return this.http.post<ViewClaimResponse>(uri, request);
  }

  changeService(
    worker: Worker,
    tour: SearchResultsResponseTour,
    rawClaim: any,
    serviceUid: string,
  ): Observable<ViewClaimResponse> {
    const uri = `${AppConfig.andromedaApiUrl}/search/change-service`;
    const request: ChangeServiceClaimParamsRequest = {
      ...this.getBaseRequestParams(worker, tour),
      rawClaim,
      serviceId: serviceUid,
    };

    return this.http.post<ViewClaimResponse>(uri, request);
  }

  changeHotel(
    worker: Worker,
    tour: SearchResultsResponseTour,
    rawClaim: any,
    newHotelId: string,
    oldHotelId: string,
  ): Observable<ViewClaimResponse> {
    const uri = `${AppConfig.andromedaApiUrl}/search/change-hotel`;
    const request: ChangeHotelClaimParamsRequest = {
      ...this.getBaseRequestParams(worker, tour),
      rawClaim,
      newHotelId,
      oldHotelId,
    };

    return this.http.post<ViewClaimResponse>(uri, request);
  }

  changeFlight(
    worker: Worker,
    tour: SearchResultsResponseTour,
    rawClaim: any,
    newFlightId: string,
    oldFlightId: string,
  ): Observable<ViewClaimResponse> {
    const uri = `${AppConfig.andromedaApiUrl}/search/change-flight`;
    const request: ChangeFlightClaimParamsRequest = {
      ...this.getBaseRequestParams(worker, tour),
      rawClaim,
      newFlightId,
      oldFlightId,
    };

    return this.http.post<ViewClaimResponse>(uri, request);
  }

  toggleAdditionalInfant(
    worker: Worker,
    tour: SearchResultsResponseTour,
    rawClaim: any,
  ): Observable<ViewClaimResponse> {
    const uri = `${AppConfig.andromedaApiUrl}/search/toggle-infant`;
    const request: ToggleInfantClaimParamsRequest = {
      ...this.getBaseRequestParams(worker, tour),
      rawClaim,
    };

    return this.http.post<ViewClaimResponse>(uri, request);
  }

  private getBaseRequestParams(worker: Worker, tour: SearchResultsResponseTour): BaseRequestParams {
    return {
      brandId: worker.brandId,
      employeeId: worker.employeeId,
      companyId: worker.companyId,
      tourId: tour.offerId,
      findOperatorId: tour.operator.id,
    };
  }
}
