import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-andromeda-claim-message',
  templateUrl: './andromeda-claim-message.component.html',
  styleUrl: './andromeda-claim-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AndromedaClaimMessageComponent {
  message = input.required<string>();
}
