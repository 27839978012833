<div appDraggable [dragHandle]="handle" class="container">
  <div #handle class="drag-handle"></div>

  <button (click)="closeModal.emit()" class="close-button" type="button">
    <img alt="" src="/assets/icons/close-icon.svg" />
  </button>

  <div class="header">Варианты номеров</div>

  <app-alert-label type="warning">{{ tour().hotel.name }}</app-alert-label>

  @if (showSearchProgress()) {
    <app-line-progress [animationDuration]="'30s'" />
  }

  @if (showError()) {
    <app-alert-label>
      Не смогли получить другие варианты размещения в отель
    </app-alert-label>
  }

  @if (showRooms()) {
    <div class="rooms" [class.one-room]="roomTours().length === 1">
      @for (roomTours of roomTours(); track roomTours.room.id) {
        <app-search-tour-room [roomTours]="roomTours" />
      }
    </div>
  }
</div>
