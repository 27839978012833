import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SearchResultGroup } from '../../deals/modules/deal-view/modules/search-tours/search-tours.model';

@Injectable({ providedIn: 'root' })
export class SearchResultService {
  private showResultComponentSub = new BehaviorSubject<boolean>(false);
  private searchResultGroupsSub = new BehaviorSubject<SearchResultGroup[]>([]);
  private showHotelOnMapSub = new Subject<number>();

  get showResultComponent$(): Observable<boolean> {
    return this.showResultComponentSub.asObservable();
  }

  showResultComponent(): void {
    this.showResultComponentSub.next(true);
  }

  hideResultComponent(): void {
    this.showResultComponentSub.next(false);
  }

  getSearchResultGroups$(): Observable<SearchResultGroup[]> {
    return this.searchResultGroupsSub.asObservable();
  }

  setSearchResultGroups(value: SearchResultGroup[]) {
    this.searchResultGroupsSub.next(value);
  }

  get showHotelOnMap$(): Observable<number> {
    return this.showHotelOnMapSub.asObservable();
  }

  showHotelOnMap(hotelId: number): void {
    this.showHotelOnMapSub.next(hotelId);
  }
}
