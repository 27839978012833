<div class="tour-container"
  [dndDraggable]="draggableData"
  [dndDisableIf]="tourState === stateVariants.notFound && tourSelectMode() ===tourSelectModeVariants.drop"
  (dndCanceled)="onDragCanceled()"
  (dndStart)="onDragStart()"
  (dndEnd)="onDragEnd()"
  (click)="showCalendar()">
  <div dndHandle class="tour-container-dnd">
    <div dndHandle class="tour-left-aside">
      @if (tourSelectMode() === tourSelectModeVariants.drop) {
        <div class="tour-draggable-hand"
          [class.not-allowed]="tourState === stateVariants.notFound">
          <img src="assets/icons/search/tour-draggable.svg">
        </div>
      }

      <div class="tour-information-container">
        <div class="tour-information">
          <div class="tour-information-main">
            <span class="tour-depart-date">{{ tour().date | date:'dd.MM' }} на {{ tourNights() }} н.</span>
            <span class="tour-operator">{{ operatorCode() }}</span>
            @if (isSplit()) {
              <app-search-tour-label-split-rooms />
            }
          </div>

          <div class="tour-information-ext">
            @if (airlines()) {
              <span class="tour-airlines">{{ airlines() }}, </span>
            }
            <span class="tour-meal">{{ mealName() }}</span>
            @if (tour().isGDS) {
              <app-search-tour-label-gds />
            }
            @if (tour().isCombined) {
              <app-search-tour-label-combined />
            }
          </div>
        </div>
      </div>
    </div>

    <div class="tour-price" [class.tour-expired]="tourExpired()">
      <div class="discount-container">
        @if (showDiscount() && tour().discount) {
          <span class="discount-percent">-{{ tour().discount }}%</span>
        }
      </div>

      @if (showConversion() && tour().hotel.conversionPercent) {
        <app-search-result-hotel-conversion [value]="tour().hotel.conversionPercent" />
      }

      @switch (tourState) {
        @case (stateVariants.notRecalc) {
          <div>
            <div class="tour-price-value"></div>
            <div class="tour-price-brand-value not-recalc"
              matTooltip="Тур устарел. Обновляем цену..."
              matTooltipHideDelay="1000"
              (mouseenter)="onMouseEnterOnPrice()"
              (mouseleave)="onMouseLeaveOnPrice()"
              [matTooltipDisabled]="!tourExpired()">
              @if (showPrice()) {
                <span>
                  {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
                </span>
              } @else {
                <button brx-button color="yellow" class="btn-show-price">Цены</button>
              }
              @if (showTourMenu()) {
                <img #tourMenu (click)="showMenu($event)"
                  class="tour-menu" src="assets/icons/three-dots-vertical.svg"
                  matTooltip="Меню туров"
                  [matTooltipDisabled]="tourExpired()">
              }
            </div>
          </div>
        }
        @case (stateVariants.notFound) {
          <div>
            <div class="tour-not-found">Тур не найден</div>
            <div class="tour-price-brand-value not-found">
              {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
            </div>
          </div>
        }
        @case (stateVariants.recalcInProgress) {
          <img class="search-tours-loader-icon" src="assets/icons/search/hotel-search-tours-loader.svg">
        }
        @case (stateVariants.recalced) {
          <div>
            <div class="tour-price-value">
              {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
            </div>
            <div class="tour-price-brand-value recalced"
              matTooltip="Тур устарел. Обновляем цену..."
              matTooltipHideDelay="5000"
              (mouseenter)="onMouseEnterOnPrice()"
              (mouseleave)="onMouseLeaveOnPrice()"
              [matTooltipDisabled]="!tourExpired()">
              <span>
                {{ recalcPrice() | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
              </span>
              @if (showTourMenu()) {
                <img #tourMenu (click)="showMenu($event)"
                  class="tour-menu" src="assets/icons/three-dots-vertical.svg" matTooltip="Меню туров">
                }
              </div>
            </div>
          }
          @case (stateVariants.error) {
            <div>
              <div class="tour-not-found">Ошибка при пересчете</div>
              <div class="tour-price-brand-value not-found">
                {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
              </div>
            </div>
          }
        }
      </div>
    </div>
  </div>
