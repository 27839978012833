
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DndDropEvent, DndModule } from 'ngx-drag-drop';
import { ChatService } from '../../../../../../core/services/chat/chat.service';
import { PopupService } from '../../../../../../shared/services/popup-service.service';
import { ContentCreatorContentType } from '../../../../../content-creator/interfaces/content-creator.interface';
import { ContentCreatorModalControlService } from '../../../../../content-creator/services/content-creator-modal-control.service';
import { SearchTourCalendarComponent } from '../../../../../search/components/calendar/search-tour-calendar.component';

@Component({
  selector: 'app-deals-view-drag-on-drop',
  templateUrl: './deal-view-drag-on-drop.component.html',
  styleUrls: ['./deal-view-drag-on-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DndModule],
})
export class DealViewDragOnDropComponent implements OnInit, OnDestroy {
  showToursDropArea = false;
  showCloseButton = false;
  customComponentRef: ComponentRef<any>;

  @ViewChild('customComponentContainer', {
    read: ViewContainerRef,
    static: true,
  })
  customComponentContainerRef: ViewContainerRef;

  @Output() onClose = new EventEmitter<void>();

  private tourCalendarComponentRef: ComponentRef<SearchTourCalendarComponent>;

  constructor(
    private readonly chatService: ChatService,
    private readonly contentCreatorModalControlService: ContentCreatorModalControlService,
    private readonly popupService: PopupService,
  ) {}

  ngOnInit() {
    if (this.customComponentRef) {
      const nativeElement: HTMLElement = this.customComponentContainerRef.element.nativeElement;
      if (nativeElement) {
        nativeElement.appendChild((this.customComponentRef.hostView as any).rootNodes[0] as HTMLElement);
      }
    }
  }

  ngOnDestroy() {
    this.customComponentRef?.destroy();
  }

  onDropTour(event: DndDropEvent): void {
    const contentCreatorType: ContentCreatorContentType = event.data.contentCreatorType;

    if (contentCreatorType === ContentCreatorContentType.ManagerPricesCalendar) {
      this.tourCalendarComponentRef = this.popupService.showPopup(SearchTourCalendarComponent);
      this.tourCalendarComponentRef.instance.tourId = event.data.tourId;
      this.tourCalendarComponentRef.instance.hotelName = event.data.hotelName;
      this.tourCalendarComponentRef.instance.initCreateRequest = event.data.calendarInitRequest;
      this.tourCalendarComponentRef.instance.selectedTour.subscribe((tourId: string) => {
        this.contentCreatorModalControlService.showManagerOfferModalByTourId(tourId);
        this.popupService.closeAllModals();
      });
      this.tourCalendarComponentRef.instance.closed.subscribe(() => {
        this.popupService.closeAllModals();
        this.tourCalendarComponentRef.destroy();
      });
    } else {
      this.contentCreatorModalControlService.showMultipleContentModalByTourId(
        event.data.tourId as string,
        contentCreatorType,
      );
    }
  }

  close(): void {
    this.onClose.emit();
  }
}
