import { ComponentRef, Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DealViewDragOnDropComponent } from '../components/drag-on-drop/deal-view-drag-on-drop.component';

@Injectable({ providedIn: 'root', deps: [ViewContainerRef] })
export class ChatDragAndDropService implements OnDestroy {
  private ddComponentOnDestroy = new Subject<void>();
  ddComponentOnDestroy$ = this.ddComponentOnDestroy.asObservable();

  private ddComponentRef: ComponentRef<DealViewDragOnDropComponent>;
  private ddComponentCloseSubscription: Subscription;
  private ddComponentCloseOnBgSubscription: Subscription;

  private isShowed = false;

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  ngOnDestroy() {
    this.ddComponentCloseSubscription?.unsubscribe();
    this.ddComponentCloseOnBgSubscription?.unsubscribe();
  }

  showArea(config?: {
    showTourDropArea?: boolean;
    showCloseButton?: boolean;
    closeOnBgClick?: boolean;
    customComponentRef?: ComponentRef<any>;
  }): void {
    if (this.isShowed) {
      this.closeArea();
      // return;
    }
    this.ddComponentRef = this.viewContainerRef.createComponent(DealViewDragOnDropComponent);
    if (config?.showTourDropArea === true) {
      this.ddComponentRef.instance.showToursDropArea = true;
    }

    if (config?.showCloseButton === true) {
      this.ddComponentRef.instance.showCloseButton = true;
      this.ddComponentCloseSubscription = this.ddComponentRef.instance.onClose.subscribe(() => {
        this.isShowed = false;
        this.closeArea();
      });
    }

    if (config?.closeOnBgClick === true) {
      this.ddComponentCloseOnBgSubscription = this.ddComponentRef.instance.onClose.subscribe(() => {
        this.isShowed = false;
        this.closeArea();
      });
    }

    if (config?.customComponentRef) {
      this.ddComponentRef.instance.customComponentRef = config.customComponentRef;
    }

    document.body.appendChild((this.ddComponentRef.hostView as any).rootNodes[0] as HTMLElement);

    this.isShowed = true;
  }

  closeArea(): void {
    this.isShowed = false;
    this.ddComponentCloseSubscription?.unsubscribe();
    this.ddComponentRef?.instance?.customComponentRef?.destroy();
    this.ddComponentRef?.destroy();
    this.ddComponentOnDestroy.next();
  }
}
