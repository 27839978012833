@if (errorMessage()) {
  <app-alert-label [type]="dangerAlertLabelType">
    {{ errorMessage() }}
  </app-alert-label>
} @else {
  <div class="count-info" appResizeObserver (heightChange)="countInfoHeight.set($event)">
    <div>Всего:</div>
    <div class="count">{{ comments.length | pluralize: 'комментарий,комментария,комментариев' }}</div>
  </div>

  <div
    class="scrollable-wrapper"
    [ngStyle]="{ '--content-creator-reserved-height': contentCreatorReservedHeight() + 'px' }"
  >
    <div
      class="comments-list"
      appTextSelection
      [appTextSelectionEnabled]="appTextSelectionEnabled"
      [appTextSelectionMenu]="menuTemplate"
      (lastSelectedText)="lastSelectedText.set($event)"
    >
      @for (comment of comments; track comment) {
        <div class="comment">
          <div class="comment-header">
            <div class="comment-author">{{ comment.author }}</div>
            <div class="comment-date">{{ comment.createdAt | date: 'dd.MM.yyyy' }}</div>
          </div>
          <div class="comment-text">{{ comment.text }}</div>
        </div>
      }
    </div>
  </div>

  <ng-template #menuTemplate>
    <app-speech-bubble>
      <button (click)="addTextToMessage(lastSelectedText())">Добавить в сообщение</button>
    </app-speech-bubble>
  </ng-template>
}
