import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { BrxButtonComponent } from '../../../../../../ui-components/hermes/button/brx-button.component';

@Component({
  selector: 'app-andromeda-btn-back-to-tours',
  template: `
    <button brx-button type="button" class="btn-show-tours-list" (click)="click.emit()">
      <img src="assets/icons/search/arrow-left.svg" alt="" />
      Вернуться к списку туров
    </button>
  `,
  styles: `
    .btn-show-tours-list {
      margin-bottom: 10px;
      width: 100%;
      padding: 0 0 10px 0;
      border-bottom: 1px solid #ccc;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BrxButtonComponent],
})
export class AndromedaBtnBackToToursComponent {
  click = output<void>();
}
