<div class="room-container">
  <div class="room">
    <div class="room-photo-container">
      @if (room.photos.length) {
        <img class="room-photo" [src]="mainPhotoUrl" (click)="showGallery()">

        <div class="room-photo-count">
          <img src="assets/icons/search/room-photo-counter.svg"> {{ room.photos.length }}
        </div>
      }
    </div>

    <div class="room-info-container">
      <h4 class="room-name">
        {{ room.name }}
        @if (room.area) {
          <span>({{ room.area }} кв.м)</span>
        }
      </h4>
      @if (description) {
        <div class="room-description"
             [class.full]="isShowFullDescription()"
             [innerHTML]="description | safeHtml"></div>

        @if (description.length > 200 && !isShowFullDescription()) {
          <button type="button" class="show-more-text" (click)="showFullDescription()">
            Показать весь текст <img src="assets/icons/favorite-hotels/chevron-down-hover.svg" />
          </button>
        }
      }
    </div>
  </div>
  <div class="room-tours">
    @for (tour of roomTours().tours; track tour.id) {
      <div class="room-tour" (click)="sendTour(tour)">
        <div class="operator-name">{{ tour.operator.code }}</div>
        <div class="tour-price">
          {{ tour.brandPrice.value | number: '1.0-0' }} {{ tour.brandPrice.currency | priceCurrency }}
        </div>
      </div>
    }
  </div>
</div>
