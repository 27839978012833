@if (isStars) {
  <p
    class="hotel-stars">
    @for (star of stars; track star) {
      <img
       [attr.src]="star ? 'assets/icons/search/hotel-stars-full.svg'
                        : 'assets/icons/search/hotel-stars-empty.svg'" />
    }
  </p>
} @else {
  <p class="default-value">{{ value }}</p>
}

