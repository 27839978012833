<div class="tour-menu">
  <ul class="tour-menu-list">
    <li class="tour-menu-list-item" (click)="createTourPackage()">
      <img src="assets/icons/search/plus-lg.svg"> Создать турпакет
    </li>

    @if (tour().searchURL) {
      <li class="tour-menu-list-item" (click)="openSearchLink()">
        <img src="assets/icons/search/search.svg"> Найти у туроператора
      </li>
    }

    @if (tour().bookURL) {
      <li class="tour-menu-list-item" (click)="openBookLink()">
        <img src="assets/icons/search/box-arrow-up-right.svg">
        Забронировать у туроператора
      </li>
    }

    <li class="tour-menu-list-item" (click)="showCheckPrice()">
      <img src="assets/icons/search/currency-dollar.svg"> Проверить цену
    </li>

    <li #flights class="tour-menu-list-item" (click)="showFlights()">
      <img src="assets/icons/search/flights.svg"> Информацию по перелетам
    </li>
    <li class="tour-menu-list-item" (click)="showRooms()">
      <img src="assets/icons/search/rooms.svg"> Варианты номеров
    </li>
  </ul>
</div>
