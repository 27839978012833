<div class="prices-container">
  <div class="price-container">
    <div class="price-header">
      <label class="price-header-label">Цена до пересчета</label>
    </div>
    <div class="price-block">
      <div class="price">
        <span class="currency">{{ initPriceCurrency() | priceCurrency }}</span> {{ initPriceValue() }}
      </div>
      <div class="brand-price">= {{ initBrandPriceValue() | number:'1.0-0' }}</div>
    </div>
  </div>

  <div class="price-container">
    <div class="price-header">
      <label class="price-header-label">Цена после пересчета</label>
    </div>
    <div class="price-block" [class.blur]="!showPrice()">
      <div class="price">
        <span class="currency">{{ priceCurrency() | priceCurrency }}</span> {{ priceValue() }}
      </div>
      <div class="brand-price">
        = {{ brandPriceValue() | number:'1.0-0' }}

        @if (commissionPercent()) {
          <button brx-button color="link" type="button" class="btn-commission" (click)="toggleCommission()">
            Комиссия
          </button>
        }
      </div>

      @if (showCommission()) {
        <div class="commission-value">
          {{ commissionPercent() }}% = {{ commissionValue() }}{{ priceCurrency() | priceCurrency }}
        </div>
      }
    </div>

    @if (calculateInProgress()) {
      <div class="calculate-progress-container">
        <app-line-progress [animationDuration]="'15s'" />
      </div>
    }

    <button brx-button color="blue"
            class="btn-calculate"
            [disabled]="calculateInProgress()"
            (click)="calculate()">
      @if (calculateInProgress()) {
        Пересчитываем цену
      } @else {
        Рассчитать цену за тур
      }
    </button>
  </div>
</div>
