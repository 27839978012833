import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HtmlSanitizerService {
  // Статическое textarea для декодирования HTML-энтити
  private static textareaElement: HTMLTextAreaElement = document.createElement('textarea');
  // Статический div для извлечения текста из HTML
  private static divElement: HTMLDivElement = document.createElement('div');

  constructor(private sanitizer: DomSanitizer) {}

  /**
   * Кодирует специальные символы в HTML-сущности
   */
  encodeHtmlEntities(text: string): string {
    // Используем textarea для преобразования символов в HTML-сущности
    HtmlSanitizerService.textareaElement.textContent = text;
    return HtmlSanitizerService.textareaElement.innerHTML;
  }

  /**
   * Декодирует HTML-сущности в специальные символы
   */
  decodeHtmlEntities(text: string): string {
    // Используем textarea, которая была создана один раз
    HtmlSanitizerService.textareaElement.innerHTML = text;
    return HtmlSanitizerService.textareaElement.value;
  }

  /**
   * Извлекает текст из HTML (удаляет все теги оставляя только текст в них)
   */
  extractTextFromHtml(html: string): string {
    HtmlSanitizerService.divElement.innerHTML = html;
    return HtmlSanitizerService.divElement.textContent || HtmlSanitizerService.divElement.innerText || '';
  }

  /**
   * Делает HTML безопасным для вставки в DOM
   */
  trustHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
