import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchResultsResponseSeatAvailable } from '../../../../../../services/search/websocket-tours.model';

@Component({
  selector: 'app-andromeda-state-show-tour-seats',
  templateUrl: './andromeda-state-show-tour-seats.component.html',
  styleUrl: './andromeda-state-show-tour-seats.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTooltip],
})
export class AirlinesStateShowTourSeatsComponent {
  label = input.required<string>();
  value = input.required<SearchResultsResponseSeatAvailable>();

  seatAvailable = SearchResultsResponseSeatAvailable;
}
