<div class="andromeda-container"
     [formGroup]="form()">
  <h4>Транспорт</h4>

  <table class="table table-gds-flights">
    <tr>
      <th rowspan="2"></th>
      <th rowspan="2">Рейс</th>
      <th rowspan="2">Время в пути</th>
      <th colspan="2">Время</th>
      <th colspan="2">Аэропорт</th>
      <th rowspan="2">Класс</th>
      <th rowspan="2">Доплата</th>
      <th rowspan="2">Багаж</th>
    </tr>
    <tr>
      <th>Вылета</th>
      <th>Прибытия</th>
      <th>Вылета</th>
      <th>Прибытия</th>
    </tr>
    @for (flightGroup of flightGroups() | keyvalue: flightGroupsOrder; track flightGroup) {
      <tr>
        <td colspan="10"></td>
      </tr>
      @for (flight of flightGroup.value; let i = $index; track flight.uid) {
        <tr>
          @if (i === 0) {
            <td [rowSpan]="flightGroup.value.length">
              <input type="radio"
                     [formControlName]="'group'"
                     [value]="flight.uid">
            </td>
          }
          <td>
            @if (flight.airline) {
              <img style="width: 65px;"
                   [attr.src]="'https://ht.kz/img/airlines/' + flight.airline +'.png'"
                   [attr.alt]="flight.full_airline || flight.full_marketing_airline"
                   [attr.title]="flight.full_airline || flight.full_marketing_airline">
            }
            <br>
            {{ flight.flight_number }}
          </td>
          <td>{{
              flight.SegmentDuration && flight.SegmentDuration !== '00:00'
                ? flight.SegmentDuration
                : (flight.fly_duration ? flight.fly_duration : '')
            }}
          </td>
          <td>
            {{ flight.DateBeg | date:'dd.MM' }}
            <br>
            {{ flight.depart_time }}
          </td>

          <td>
            {{ flight.DateEnd | date:'dd.MM' }}
            <br>
            {{ flight.arrival_time }}
          </td>
          <td>
                    <span class="psevdo-link" [title]="flight.DepartureAirportName">
                        {{ flight.DepartureAirportCode }}
                    </span>
          </td>
          <td>
                    <span class="psevdo-link" [title]="flight.ArrivalAirportName">
                        {{ flight.ArrivalAirportCode }}
                    </span>
          </td>
          <td>{{ flight.class }}</td>

          @if (i === 0) {
            <td [rowSpan]="flightGroup.value.length"> + {{ flight.markup }}
              {{ flight.currency }}
            </td>

            <td [rowSpan]="flightGroup.value.length">
              @if (flight.bagage_note) {
                <span class="psevdo-link"
                      [title]="flight.bagage_note">
                            <span class="glyphicon glyphicon-question-sign"></span>
                  {{ flight.bagage }}
                        </span>
              }
            </td>
          }
        </tr>
      }
    }
  </table>
</div>
