import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HotelReview, HotelTextReviewsWithTagsRequest } from '@api-clients/api-client';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReviewsApiService {
  constructor(private http: HttpClient) {}

  getTextReviewsWithTags(hotelId: number, tagIds: number[]): Observable<HotelReview[]> {
    const request: HotelTextReviewsWithTagsRequest = {
      tagIds,
    };
    return this.http.post<HotelReview[]>(
      `${AppConfig.commonApiUrl}/hotels/text-reviews-with-tags/${hotelId}`,
      {
        ...request,
      },
    );
  }
}
