import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Hotel } from '../../../../services/andromeda.model';

@Component({
  selector: 'app-andromeda-claim-hotels',
  templateUrl: './andromeda-claim-hotels.component.html',
  styleUrl: './andromeda-claim-hotels.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, DatePipe],
})
export class AndromedaClaimHotelsComponent {
  hotels = input<Hotel[]>();
  variants = input<Hotel[]>();
  form = input<FormGroup>();

  hotel = computed<Hotel>(() => this.hotels()[0]);
  note = computed<string>(() => {
    const hotel = this.hotel();
    if (hotel.hasOwnProperty('note')) {
      return hotel.note[0]._;
    }
    return '';
  });
  mealNote = computed<string>(() => {
    const hotel = this.hotel();
    if (hotel.hasOwnProperty('mealNote')) {
      return hotel.mealNote[0]._;
    }
    return '';
  });
}
