import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { WorkerStateService } from '../../../../core/services';
import { Worker } from '../../../../models';
import { createInitSearchRequestFromTour } from '../../helpers/init-search-request.helper';
import { WebsocketToursSearchService } from '../../services/search/websocket-tours-search.service';
import {
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestType,
  SearchConfig,
  SearchResultsResponseTour,
} from '../../services/search/websocket-tours.model';
import { AndromedaBtnBackToToursComponent } from './components/btn-back-to-tours/andromeda-btn-back-to-tours.component';
import { AndromedaErrorComponent } from './components/error/andromeda-error.component';
import { AndromedaStateLoadingClaimComponent } from './components/state-loading-claim/andromeda-state-loading-claim.component';
import { AndromedaStateLoadingGdsClaimComponent } from './components/state-loading-gds-claim/andromeda-state-loading-gds-claim.component';
import { AndromedaStateShowClaimComponent } from './components/state-show-claim/andromeda-state-show-claim.component';
import { AndromedaStateShowLoaderComponent } from './components/state-show-loader/andromeda-state-show-loader.component';
import { AndromedaStateShowToursComponent } from './components/state-show-tours/andromeda-state-show-tours.component';
import { AndromedaApiService } from './services/andromeda-api.service';
import { AndromedaUiService } from './services/andromeda-ui.service';
import { Claim } from './services/andromeda.model';

export enum ViewState {
  showClaim = 'showClaim',
  showTours = 'showTours',
  showLoader = 'showLoader',
  showClaimLoader = 'showClaimLoader',
  showClaimGdsLoader = 'showClaimGdsLoader',
  showClaimNotLoaded = 'showClaimNotLoaded',
}

@Component({
  selector: 'app-search-tour-andromeda-container',
  templateUrl: './andromeda-container.component.html',
  styleUrl: './andromeda-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AndromedaStateShowLoaderComponent,
    AndromedaStateShowToursComponent,
    AndromedaStateLoadingClaimComponent,
    AndromedaStateLoadingGdsClaimComponent,
    AndromedaStateShowClaimComponent,
    AndromedaBtnBackToToursComponent,
    AndromedaErrorComponent,
  ],
  providers: [AndromedaApiService, AndromedaUiService],
})
export class AndromedaContainerComponent implements OnInit {
  initTour = input.required<SearchResultsResponseTour>();

  currentViewState = signal<ViewState>(ViewState.showLoader);
  selectedTour = signal<SearchResultsResponseTour>(null);
  selectedClaim = signal<Claim>(null);
  isGDS = signal<boolean>(false);

  apiErrorResponse = signal<HttpErrorResponse>(null);
  currentWorker: Worker;

  foundTours: SearchResultsResponseTour[] = [];

  viewState = ViewState;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly searchToursService: WebsocketToursSearchService,
    private readonly andromedaService: AndromedaApiService,
    private readonly workerStateService: WorkerStateService,
  ) {}

  ngOnInit(): void {
    this.workerStateService.currentWorker$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        filter(worker => !!worker),
      )
      .subscribe(worker => {
        this.currentWorker = worker;
      });

    this.searchTours();
  }

  setSelectedTour(tour: SearchResultsResponseTour): void {
    // Поиск идет через Андромеду и оператор ставится AND, а нам нужно реального
    tour.operator = this.initTour().operator;

    this.selectedTour.set(tour);
    this.currentViewState.set(ViewState.showClaimLoader);

    this.loadClam();
  }

  showToursList(): void {
    this.currentViewState.set(ViewState.showTours);
  }

  private searchTours(): void {
    const initSearchRequest = createInitSearchRequestFromTour(this.initTour());
    initSearchRequest.params.options.type = InitSearchRequestType.andromeda;
    initSearchRequest.params.params.andromedaOperatorId = this.initTour().operator.id;

    const searchConfig: SearchConfig = {
      addToursInMainResult: false,
      isMainSearch: false,
      groupMode: InitSearchRequestOptionsGroupResult.noGroup,
    };
    this.searchToursService
      .searchTours(initSearchRequest, searchConfig)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result.searchIsDone) {
          this.foundTours = result.tours;
          if (this.foundTours.length <= 1) {
            this.selectedTour.set(this.initTour());
            this.currentViewState.set(ViewState.showClaimLoader);
            this.loadClam();
          } else {
            this.foundTours.sort((a, b) => a.brandPrice.value - b.brandPrice.value);
            this.currentViewState.set(ViewState.showTours);
          }
        }
      });
  }

  private loadClam(): void {
    this.andromedaService
      .loadClaim(this.currentWorker, this.selectedTour())
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(result => {
          this.isGDS.set(result.isGDS);
          if (result.isGDS) {
            this.currentViewState.set(ViewState.showClaimGdsLoader);
            return this.andromedaService.loadGdsClaim(this.currentWorker, this.selectedTour());
          }
          return of(result);
        }),
      )
      .subscribe({
        next: result => {
          this.selectedClaim.set(result.rawClaim);
          this.currentViewState.set(ViewState.showClaim);
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.apiErrorResponse.set(error);
          this.currentViewState.set(ViewState.showClaimNotLoaded);
        },
      });
  }
}
