<div class="andromeda-container" [formGroup]="form()">
  <h4>Проживание</h4>

  <table class="table">
    <thead>
    <tr>
      <th>Гостиница</th>
      <th>Город</th>
      <th>Номер</th>
      <th>Размещение</th>
      <th>Питание</th>
      <th>Период проживания</th>
    </tr>
    </thead>
    <tbody>
      @for (hotelGroup of hotelGroups() | keyvalue; track hotelGroup) {
        <tr>
          <td colspan="5">
            <select class="form-control" [formControlName]="'group' + hotelGroup.value.id">
              @for (hotel of hotelGroup.value.hotels; track hotel.uid) {
                <option [value]="hotel.uid">
                  {{ hotel.title }}

                  @if (hotel.supplement) {
                    {{ formatMarkup(hotel) }}
                  }
                </option>
              }
            </select>
          </td>
          <td>{{ hotelGroup.value.databeg | date:'dd.MM' }} - {{ hotelGroup.value.dateend | date:'dd.MM' }}</td>
        </tr>
      }
    </tbody>
  </table>
</div>
