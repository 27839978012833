
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'app-hotel-stars',
  templateUrl: './search-hotel-stars.component.html',
  styleUrls: ['./search-hotel-stars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class SearchHotelStarsComponent {
  isStars = true;
  private starsValue;

  @Input()
  set value(stars: any) {
    this.starsValue = stars;
    this.populateStars();
    this.cdRef.markForCheck();
  }

  get value() {
    return this.starsValue;
  }

  stars: boolean[] = [false, false, false, false, false];

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  private populateStars() {
    const value = Number(this.value);
    // value !== 0 нужен тогда, когда нам приходит звездность ''
    // Number('') === 0
    if (!Number.isNaN(value) && value !== 0) {
      this.stars = [false, false, false, false, false];
      this.isStars = true;
      for (let index = 0; index < value && index < 5; index++) {
        this.stars[index] = true;
      }
    } else {
      this.isStars = false;
    }
  }
}
