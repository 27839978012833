<div class="andromeda-container" [formGroup]="form()">
  <h4>{{ label() }}</h4>

  @if (filters().length) {
    <label class="services-header">Выберите какие услуги показать</label>
    <div class="filters-list">
      @for (filter of filters(); track filter.id) {
        <div class="filters-item">
          <div class="checkbox">
            <label>
              <input type="checkbox"
                     (click)="toggleActive(filter.id)"
                     [checked]="isActive(filter.id)">
              {{ filter.description }}
            </label>
          </div>
        </div>
      }
    </div>
  }

  <div class="service-items">
    @for (service of services(); track service.uid) {
      @if (!showFilters() || (showFilters() && isActive(service.groupId))) {
        <div class="service-item">
          <div class="service-name">
            <div class="checkbox">
              <label>
                <input type="checkbox"
                       [formControlName]="service.uid"
                       [attr.disabled]="service.required === 'true' ? true : null"
                       (change)="changeValue($event, service.uid)">
                {{ service.title }}
                <br>
                @if (service.note) {
                  <span class="text-muted service-note"
                        [innerHtml]="extractNote(service)"></span>
                }
              </label>
            </div>
          </div>
          <div class="service-price">
            @if (service.price) {
              {{ service.price }} {{ service.currencyAlias }}
            }
          </div>
        </div>
      }
    }
  </div>
</div>
