<div class="andromeda-container" [formGroup]="form()">
  <h4>Транспорт</h4>

  @for (flightGroup of flightGroups() | keyvalue; track flightGroup) {
    <label class="group-name">
      {{ flightGroup.value.name }}
      @if (flightGroup.value.required) {
        <span class="required">*</span>
      }
    </label>

    <select class="form-control" [formControlName]="'group' + flightGroup.value.id">
      @for (flight of flightGroup.value.flights; track flight.uid) {
        <option [value]="flight.uid">
          {{ flight | flightName }}
        </option>
      }
    </select>

    @if (flightGroup.value.note) {
      <div [innerHTML]="flightGroup.value.note" class="text-muted"></div>
    }
  }
</div>
