import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-search-hotel-photo',
  templateUrl: './search-hotel-photo.component.html',
  styleUrls: ['./search-hotel-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchHotelPhotoComponent implements OnInit {
  @Input() hotelPhoto: string;

  hotelPhotoUrl: SafeStyle;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.hotelPhotoUrl = this.sanitizer.bypassSecurityTrustStyle(`url('https://static.ht.kz/img/h/sm4x3/${this.hotelPhoto}')`);
  }
}
