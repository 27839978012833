import { DatePipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PluralizePipe } from '../../../../../../../../../../../helpers/pipes/plural/pluralize.pipe';
import { PopupService } from '../../../../../../../../../../../shared/services/popup-service.service';
import { ContentCreatorModalControlService } from '../../../../../../../../../../content-creator/services/content-creator-modal-control.service';
import { SearchTourCalendarComponent } from '../../../../../../../../../../search/components/calendar/search-tour-calendar.component';
import { SearchBookingRatingComponent } from '../../../../../../../../../../search/components/hotel/hotel-booking-rating/search-hotel-booking-rating.component';
import {
  SearchHotelModalComponent,
  Tabs,
} from '../../../../../../../../../../search/components/hotel/hotel-modal/search-hotel-modal.component';
import { SearchHotelSaleCountComponent } from '../../../../../../../../../../search/components/hotel/hotel-sales-count/search-hotel-sales-count.component';
import { SearchHotelStarsComponent } from '../../../../../../../../../../search/components/hotel/hotel-stars/search-hotel-stars.component';
import { SearchRecalcModalComponent } from '../../../../../../../../../../search/components/recalc-modal/search-recalc-modal.component';
import { SearchTourLabelCombinedComponent } from '../../../../../../../../../../search/components/tour/label-combi/search-tour-label-combined.component';
import { SearchResultHotelConversionComponent } from '../../../../../../../../../../search/components/tour/label-conversion/search-result-hotel-conversion.component';
import { SearchTourLabelGdsComponent } from '../../../../../../../../../../search/components/tour/label-gds/search-tour-label-gds.component';
import { SearchTourLabelSplitRoomsComponent } from '../../../../../../../../../../search/components/tour/label-split-rooms/search-tour-label-split-rooms.component';
import { PriceCurrencyPipe } from '../../../../../../../../../../search/pipes/price-currency.pipe';
import { SearchHotelBeachTypePipe } from '../../../../../../../../../../search/pipes/search-hotel-beach-type.pipe';
import { SearchHotel } from '../../../../../../../../../../search/search.model';
import { SearchTourFlightsService } from '../../../../../../../../../../search/services/search-tour-flights.service';
import { WebsocketToursSearchService } from '../../../../../../../../../../search/services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestType,
  SearchResult,
  SearchResultsResponseTour,
} from '../../../../../../../../../../search/services/search/websocket-tours.model';
import { HotelTagWithPriority } from '../../../../../search-tours.model';
import { SearchResultMapInfoWindowTourFlightsComponent } from './flights/search-result-map-info-window-tour-flights.component';
import { SearchResultMapInfoStoriesComponent } from './stories/search-result-map-info-stories.component';

@Component({
  selector: 'app-search-result-map-info-window',
  templateUrl: './search-result-map-info-window.component.html',
  styleUrl: './search-result-map-info-window.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SearchBookingRatingComponent,
    DatePipe,
    DecimalPipe,
    PriceCurrencyPipe,
    SearchHotelStarsComponent,
    SearchHotelSaleCountComponent,
    SearchResultMapInfoWindowTourFlightsComponent,
    SearchTourLabelSplitRoomsComponent,
    SearchTourLabelGdsComponent,
    SearchTourLabelCombinedComponent,
    SearchHotelBeachTypePipe,
    PluralizePipe,
    SearchResultHotelConversionComponent,
    SearchResultMapInfoStoriesComponent,
  ],
  providers: [PopupService],
})
export class SearchResultMapInfoWindowComponent implements OnInit, OnDestroy {
  @Input() initSearchRequest: InitSearchRequest;
  @Input() tour: SearchResultsResponseTour;
  @Input() hotel: SearchHotel;
  @Input() tags: HotelTagWithPriority[] = [];

  close = output<void>();

  recalcRef = viewChild<ElementRef>('recalc');

  searchInProgress = signal<boolean>(false);
  isTourResearched = signal<boolean>(false);
  showTour = signal<boolean>(false);
  showTags = signal<boolean>(false);
  showFlightsDetail = signal<boolean>(false);

  startSearchTours = output<number>();
  hotelInfoTabs = Tabs;
  hotelPhotoUrl: SafeStyle;

  private tourCalendarComponentRef: ComponentRef<SearchTourCalendarComponent>;
  private recalcModalComponentRef: ComponentRef<SearchRecalcModalComponent>;
  private hotelInformationModalRef: ComponentRef<SearchHotelModalComponent>;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly cdRef: ChangeDetectorRef,
    private readonly contentCreatorModalControlService: ContentCreatorModalControlService,
    private readonly popupService: PopupService,
    private readonly searchToursService: WebsocketToursSearchService,
    private readonly tourFlightsService: SearchTourFlightsService,
  ) {}

  ngOnInit() {
    const hasTour = !!this.tour;

    this.showTour.set(hasTour);
    this.createHotelPhotoUrl();

    this.showTags.set(!!this.tags.length);

    if (hasTour) {
      this.tourFlightsService.load$(this.tour.id).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }
  }

  ngOnDestroy() {
    this.hotelInformationModalRef?.destroy();
  }

  get isHotel(): boolean {
    const numberStars = Number(this.hotel.stars);
    return !Number.isNaN(numberStars) && numberStars !== 0;
  }

  get airlines(): string {
    if (this.tour.freights.airlines) {
      return this.tour.freights.airlines.map(v => v.name).join('+');
    }
    return '';
  }

  get operatorCode(): string {
    return this.tour.operator.code;
  }

  get mealName(): string {
    return this.tour.meal.name || this.tour.meal.code;
  }

  get tourNights(): string {
    const nights = this.tour.nights;
    let text = nights.toString();
    if (this.tour.nightsOnWay > 0) {
      text += `+${this.tour.nightsOnWay}`;
    }

    return text;
  }

  get roomName(): string {
    if (this.tour.rooms.length === 1) {
      return this.tour.rooms[0].name;
    }
    if (this.tour.rooms[0].name === this.tour.rooms[1].name) {
      return `${this.tour.rooms[0].name} x2`;
    }

    return `${this.tour.rooms[0].name} + ${this.tour.rooms[1].name}`;
  }

  get isSplitRooms(): boolean {
    return this.tour.rooms.length > 1;
  }

  get tourists(): string {
    let label = `${this.tour.tourists.adults} взр`;
    const childrenCount = this.tour.tourists?.childAges?.length || 0;
    if (childrenCount > 0) {
      label += ` ${childrenCount} реб`;
    }

    return label;
  }

  get hasServices(): boolean {
    return !!this.hotel.seaLine || !!this.hotel.beachType;
  }

  get hasVideo(): boolean {
    return !!this.hotel.videoStories?.length;
  }

  sendTour() {
    if (this.isSplitRooms) {
      this.contentCreatorModalControlService.showManagerOfferModalByTourId(this.tour.id);
    } else {
      this.tourCalendarComponentRef = this.popupService.showPopup(SearchTourCalendarComponent);
      this.tourCalendarComponentRef.instance.tourId = this.tour.id;
      this.tourCalendarComponentRef.instance.hotelName = this.tour.hotel.name;
      this.tourCalendarComponentRef.instance.notGDS = this.initSearchRequest.params.params.notGDS;
      this.tourCalendarComponentRef.instance.selectedTour.subscribe((tourId: string) => {
        this.contentCreatorModalControlService.showManagerOfferModalByTourId(tourId);
        this.popupService.closeAllModals();
      });
      this.tourCalendarComponentRef.instance.closed.subscribe(() => {
        this.popupService.closeAllModals();
        this.tourCalendarComponentRef.destroy();
      });

      this.tourCalendarComponentRef.changeDetectorRef.detectChanges();
      this.popupService.updatePosition();
    }
  }

  recalcTour() {
    const initFormParams = {
      adults: this.tour?.tourists.adults || this.initSearchRequest.params.params.adults,
      childAges: this.tour?.tourists.childAges || this.initSearchRequest.params.params.childrenAges,
    };

    this.recalcModalComponentRef = this.popupService.showPopup(
      SearchRecalcModalComponent,
      {},
      { anchorElement: this.recalcRef() },
    );
    this.recalcModalComponentRef.setInput('initFormParams', initFormParams);
    this.recalcModalComponentRef.instance.onSubmit.subscribe(tourists => {
      const initSearchRequest = this.getNewSearchRequest();
      initSearchRequest.params.params.adults = tourists.adults;
      initSearchRequest.params.params.childrenAges = tourists.childAges;

      this.searchInProgress.set(true);
      this.searchToursService
        .searchTours(initSearchRequest, { isMainSearch: false })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.processSearchResultFn.bind(this));
    });
    this.recalcModalComponentRef.instance.onClose.subscribe(() => {
      this.popupService.closeAllModals();
    });

    this.recalcModalComponentRef.changeDetectorRef.detectChanges();
    this.popupService.updatePosition();
  }

  startResearchTours() {
    if (this.searchInProgress()) {
      return;
    }

    this.searchInProgress.set(true);

    const researchInitRequest = this.getNewSearchRequest();
    this.searchToursService
      .searchTours(researchInitRequest, { isMainSearch: false })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.processSearchResultFn.bind(this));
  }

  toggleFlightsDetail(): void {
    this.showFlightsDetail.set(!this.showFlightsDetail());

    this.cdRef.detectChanges();
  }

  showHotelInfo(modalActiveTab?: Tabs): void {
    this.hotelInformationModalRef = this.popupService.showPopup(SearchHotelModalComponent);

    if (modalActiveTab) {
      this.hotelInformationModalRef.setInput('defaultTab', modalActiveTab);
    }
    this.hotelInformationModalRef.setInput('hotelId', this.hotel.id);
    this.hotelInformationModalRef.setInput('tourId', this.tour.id);
    this.hotelInformationModalRef.instance.closeModal.subscribe(() => {
      this.popupService.closeAllModals();
    });

    if (this.tags.length) {
      const tagIds = this.tags.map(tag => tag.tag.id);
      this.hotelInformationModalRef.setInput('tagIds', tagIds);
    }

    this.popupService.updatePosition();
    this.hotelInformationModalRef.changeDetectorRef.detectChanges();
  }

  private getNewSearchRequest(): InitSearchRequest {
    const researchInitRequest = this.initSearchRequest;
    researchInitRequest.id = WebsocketToursSearchService.generateId();
    researchInitRequest.params.options.type = InitSearchRequestType.calc;
    researchInitRequest.params.params.hotels = [this.hotel.id];

    return researchInitRequest;
  }

  private processSearchResultFn(result: SearchResult): void {
    if (result.searchIsDone) {
      this.searchInProgress.set(false);
      this.isTourResearched.set(true);
    }
    if (result.hasTours) {
      const tours = result.tours;
      if (tours.length) {
        this.tour = tours[0];
        this.showTour.set(true);
      }
    }

    this.cdRef.detectChanges();
  }

  private createHotelPhotoUrl() {
    this.hotelPhotoUrl = this.sanitizer.bypassSecurityTrustStyle(
      `url('https://static.ht.kz/img/h/sm4x3/${this.hotel.photo}')`,
    );

    this.cdRef.detectChanges();
  }
}
