import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  input,
  NO_ERRORS_SCHEMA,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DraggableDirective } from '../../shared/directives/draggable.directive';

@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrl: './browser.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  imports: [DraggableDirective],
})
export class BrowserComponent implements OnInit, AfterViewInit {
  url = input.required<string>();
  trustedUrl = signal<SafeUrl>(null);

  closed = output<void>();

  width = signal<number>(0);
  height = signal<number>(0);

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.trustedUrl.set(this.sanitizer.bypassSecurityTrustUrl(this.url()));

    this.width.set(window.innerWidth);
    this.height.set(window.innerHeight);
  }

  ngAfterViewInit(): void {
    const webview: any = document.querySelector('webview');
    if (webview) {
      webview.src = this.url();
    }
  }
}
