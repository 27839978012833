import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { DraggableDirective } from '../../../../../shared/directives/draggable.directive';
import { AndromedaContainerComponent } from '../../../modules/andromeda/andromeda-container.component';
import { SearchResultsResponseTour } from '../../../services/search/websocket-tours.model';

@Component({
  selector: 'app-search-tour-check-price-popup',
  templateUrl: './search-tour-check-price-popup.component.html',
  styleUrl: './search-tour-check-price-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AndromedaContainerComponent, DraggableDirective],
})
export class SearchTourCheckPricePopupComponent {
  tour = input.required<SearchResultsResponseTour>();
  closePopup = output<void>();
}
