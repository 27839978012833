import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'touristAge',
  standalone: true,
})
export class TouristAgePipe implements PipeTransform {
  public transform(value: string): string {
    switch (value) {
      case 'ADL':
        return 'Взрослый';
      case 'CHD':
        return 'Ребенок';
      case 'INF':
        return 'Младенец';
      default:
        return value;
    }
  }
}
