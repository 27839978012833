import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TourFlightSeatsAvailablePipe } from '../../../../../../deals/modules/deal-view/modules/search-tours/components/search-result/components/map/info-window/flights/seats/pipe/tour-flight-seats-available.pipe';
import { SearchResultsResponseFreights } from '../../../../../services/search/websocket-tours.model';
import { AirlinesStateShowTourSeatsComponent } from './seats/andromeda-state-show-tour-seats.component';

@Component({
  selector: 'app-andromeda-state-show-tour-airlines',
  templateUrl: './andromeda-state-show-tour-airlines.component.html',
  styleUrl: './andromeda-state-show-tour-airlines.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TourFlightSeatsAvailablePipe, AirlinesStateShowTourSeatsComponent],
})
export class AndromedaStateShowTourAirlinesComponent {
  freights = input.required<SearchResultsResponseFreights>();
  departureTimes = input<string[]>([]);
}
