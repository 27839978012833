<div class="andromeda-container">
  <h4>{{ claimDocument().tour }}</h4>

  <div class="provider-container">
    <div class="provider-name">Оператор: {{ provider().name }}</div>

    @if (allowPrebooking()) {
      <div class="prebooking">
        <img src="assets/icons/favorite-hotels/clock.svg" alt="">
        Можно создать предварительную заявку
      </div>
    }

    <div class="moment-confirm"
         [class.moment-confirm-allow]="momentConfirm()">
      <img src="assets/icons/favorite-hotels/fire.svg" alt="">
      Моментальное подтверждение: <span [innerText]="momentConfirm() ? 'Да' : 'Нет'"></span>
    </div>
  </div>
</div>
