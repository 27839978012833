import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Transport } from '../../../services/andromeda.model';

@Pipe({
  name: 'flightName',
  standalone: true,
})
export class FlightNamePipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe) {}

  transform(flight: Transport): string {
    const ticketClass = this.recognizeClass(flight.class);

    const departureTown = flight.departure[0].town;
    const arrivalTown = flight.arrival[0].town;
    const departureDate = this.datePipe.transform(flight.datebeg, 'dd.MM');
    const departureTime = `[${departureDate} ${flight.departure[0].time}]`;
    const arrivalDate = this.datePipe.transform(flight.dateend, 'dd.MM');
    const arrivalTime = `[${arrivalDate} ${flight.arrival[0].time}]`;

    let departurePort = flight.departure[0]?.portAlias || flight.departure[0].port;
    let arrivalPort = flight.arrival[0]?.portAlias || flight.arrival[0].port;
    let baggageNote = '';
    const tranType = flight.trantype ? flight.trantype : '';

    if (flight.details) {
      arrivalPort = flight.details[0].detail.DepartureAirportName;
      departurePort = flight.details[0].detail.ArrivalAirportName;
      baggageNote = flight.details[0].detail?.bagage_note || '';
    }

    return `[${ticketClass}] ${flight.name} (${departureTown} ${departurePort} ${departureTime} - ${arrivalTown} ${arrivalPort} ${arrivalTime}) ${tranType} ${baggageNote}`;
  }

  private recognizeClass(ticketClass: string): string {
    if (
      ['W', 'S', 'Y', 'B', 'H', 'K', 'K', 'M', 'N', 'Q', 'T', 'X', 'O', 'V', 'G', 'ECONOM'].indexOf(
        ticketClass,
      ) !== -1
    ) {
      return 'Эконом';
    }
    if (['J', 'C', 'D', 'Z', 'I'].indexOf(ticketClass) !== -1) {
      return 'Бизнес';
    }
    if (['P', 'F', 'A'].indexOf(ticketClass) !== -1) {
      return 'Первый класс';
    }

    return ticketClass;
  }
}
