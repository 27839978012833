import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberFormat]',
  standalone: true,
})
export class NumberFormatDirective implements OnInit {
  constructor(private el: ElementRef, private control: NgControl) {}

  ngOnInit(): void {
    this.formatAndSetValue(String(this.control.value));
  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const currentPos = input.selectionStart || 0;

    const oldValue = input.value;
    this.formatAndSetValue(oldValue);

    const newPos = this.calculateCursorPosition(oldValue, input.value, currentPos);
    setTimeout(() => {
      input.setSelectionRange(newPos, newPos);
    }, 0);
  }

  private formatAndSetValue(value: string): void {
    if (value) {
      value = value.replace(/[^0-9,.]/g, '');
      value = value.replace(/,/g, '.');
      const parts = value.split('.');
      let integerPart = parts[0];
      const decimalPart = parts[1] ? '.' + parts[1].slice(0, 2) : '';

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      const formattedValue = integerPart + decimalPart;
      this.el.nativeElement.value = formattedValue;
      this.control.control?.setValue(formattedValue, { emitEvent: false });
    }
  }

  private calculateCursorPosition(oldValue: string, newValue: string, currentPos: number): number {
    const oldLength = oldValue.length;
    const newLength = newValue.length;

    const lengthDifference = newLength - oldLength;

    return currentPos + lengthDifference;
  }
}
