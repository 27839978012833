import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  input,
  OnDestroy,
  viewChild,
} from '@angular/core';
import { WebviewSupportService } from '../../../../../core/services';
import { ElectronService } from '../../../../../core/services/electron.service';
import { PopupService } from '../../../../../shared/services/popup-service.service';
import { TourPackageCreateService } from '../../../../../shared/services/tour-package-create.service';
import { BrowserComponent } from '../../../../browser/browser.component';
import { InitSearchRequest, SearchResultsResponseTour } from '../../../services/search/websocket-tours.model';
import { SearchTourCheckPricePopupComponent } from '../check-price/search-tour-check-price-popup.component';
import { SearchTourFlightsPopupComponent } from '../flights/search-tour-flights-popup.component';
import { SearchTourRoomsComponent } from '../room-variants/search-tour-rooms.component';

@Component({
  selector: 'app-search-tour-menu',
  templateUrl: './search-tour-menu.component.html',
  styleUrl: './search-tour-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [PopupService],
})
export class SearchTourMenuComponent implements OnDestroy {
  tour = input.required<SearchResultsResponseTour>();
  initSearchRequest = input<InitSearchRequest>();

  flightsRef = viewChild<ElementRef>('flights');

  private flightsComponentRef: ComponentRef<SearchTourFlightsPopupComponent>;
  private roomsComponentRef: ComponentRef<SearchTourRoomsComponent>;
  private browserRef: ComponentRef<BrowserComponent>;
  private checkPriceRef: ComponentRef<SearchTourCheckPricePopupComponent>;

  constructor(
    private readonly electronService: ElectronService,
    private readonly popupService: PopupService,
    private readonly tourPackageCreateService: TourPackageCreateService,
    private readonly webviewSupportService: WebviewSupportService,
  ) {}

  ngOnDestroy(): void {
    this.flightsComponentRef?.destroy();
    this.roomsComponentRef?.destroy();
    this.browserRef?.destroy();
    this.checkPriceRef?.destroy();
  }

  createTourPackage(): void {
    this.tourPackageCreateService.createTourPackage(this.tour().id);
  }

  openBookLink(): void {
    const link = this.tour().bookURL;
    if (this.webviewSupportService.isWebviewSupported()) {
      this.openBrowser(link);
    } else {
      this.openExternalLink(link);
    }
  }

  openSearchLink(): void {
    const link = this.tour().searchURL;
    if (this.webviewSupportService.isWebviewSupported()) {
      this.openBrowser(link);
    } else {
      this.openExternalLink(link);
    }
  }

  showRooms(): void {
    this.roomsComponentRef = this.popupService.showPopup(SearchTourRoomsComponent);
    this.roomsComponentRef.setInput('tour', this.tour());
    this.roomsComponentRef.setInput('initSearchRequest', this.initSearchRequest());
    this.roomsComponentRef.instance.closeModal.subscribe(() => {
      this.popupService.closeAllModals();
      this.roomsComponentRef.destroy();
    });
  }

  showFlights(): void {
    this.flightsComponentRef = this.popupService.showPopup(
      SearchTourFlightsPopupComponent,
      {},
      {
        anchorElement: this.flightsRef(),
      },
    );
    this.flightsComponentRef.setInput('tour', this.tour());
    this.flightsComponentRef.instance.closePopup.subscribe(() => {
      this.popupService.closeAllModals();
      this.flightsComponentRef.destroy();
    });
  }

  showCheckPrice(): void {
    this.checkPriceRef = this.popupService.showPopup(SearchTourCheckPricePopupComponent);
    this.checkPriceRef.setInput('tour', this.tour());
    this.checkPriceRef.instance.closePopup.subscribe(() => {
      this.popupService.closeAllModals();
      this.flightsComponentRef.destroy();
    });
  }

  private openBrowser(url: string): void {
    this.browserRef = this.popupService.showPopup(BrowserComponent);

    this.browserRef.setInput('url', url);
    this.browserRef.instance.closed.subscribe(() => {
      this.popupService.closeAllModals();
    });
  }

  private openExternalLink(link: string): void {
    this.electronService.shell.openExternal(link, { activate: true }).then();
  }
}
