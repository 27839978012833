import { HotelTag } from '@api-clients/api-client';
import { SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { SearchHotel } from '../../../../../search/search.model';
import { SearchResultsResponseTour } from '../../../../../search/services/search/websocket-tours.model';

export interface MultipleSearchFormParams {
  tourists: {
    adults: number;
    childAges: number[];
    splitRooms: boolean;
  };
  dates: {
    from: Date;
    to: Date;
  };
  nights: {
    from: number;
    to: number;
  };
  countryIds: number[];
  departCityId: number;
  hotelIds: number[];
  mealIds: number[];
  operatorIds: number[];
  airlineIds: string[];
  stars: number[];
  notGDS: boolean;
  combined?: number;
}

export interface SingleSearchFormParams {
  tourists: {
    adults: number;
    childAges: number[];
    splitRooms: boolean;
  };
  dates: {
    from: Date;
    to: Date;
  };
  nights: {
    from: number;
    to: number;
  };
  direction: SearchFormParamsDirection;
  departCityId: number;
  hotelIds: number[];
  mealIds: number[];
  operatorIds: number[];
  airlineIds: string[];
  stars: number[];
  notGDS: boolean;
  combined?: string;
  templateId?: number;
}

export interface SearchFormParamsDirection {
  countryId: number;
  regionIds: number[];
}

export enum SearchResultSortDirection {
  salesCount = 'salesCount',
  priceAsc = 'priceAsc',
  discountDesc = 'discountDesc',
  hotelRating = 'hotelRating',
  conversion = 'conversion',
}

export interface SearchFormSelectedCountry {
  country: SearchFormParamsCountry;
  regions: SearchFormParamsRegion[];
}

export const SearchResultDefaultSortDirection = SearchResultSortDirection.priceAsc;

export class SearchResultGroup {
  hotel: SearchHotel;
  tours: SearchResultsResponseTour[];
}

export interface PostFilterPrices {
  from: number;
  to: number;
}

export interface HotelTagWithPriority {
  priority: number;
  tag: HotelTag;
}
