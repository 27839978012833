<div class="modal">
  <div class="modal-header">
    <div class="modal-header-text">Пересчитать</div>
    <div class="modal-header-close">
      <button (click)="close()" type="button" class="btn-close">
        <img src="assets/icons/search/recalc-modal-close.svg" class="btn-close-icon">
      </button>
    </div>
  </div>
  <div class="modal-body" [formGroup]="form">
    <div class="counters">
      <div class="counter">
        <span class="counter-label">Взрослые</span>

        <div class="control-container">
          <button type="button" class="btn-control" (click)="decAdults()">-</button>
          <div class="control-value">{{ adults }}</div>
          <button type="button" class="btn-control" (click)="incAdults()">+</button>
        </div>
      </div>
      <div class="counter">
        <span class="counter-label">Дети до 15 лет</span>

        <div class="control-container">
          <button type="button" class="btn-control" (click)="decChild()">-</button>
          <div class="control-value">{{ child }}</div>
          <button type="button" class="btn-control" (click)="incChild()">+</button>
        </div>
      </div>
    </div>

    @if (childControl.controls.length > 0) {
      <div
        class="child-ages-container">
        Возврат детей (до 15 лет)
        <div formArrayName="childAges"
             class="child-ages-controls">
          @for (childAge of childControl.controls; track childAge; let i = $index) {
            <input [formControlName]="i"
                   appAutofocus
                   type="text"
                   class="child-ages-form-control">
          }
        </div>
      </div>
    }

    @if (canSearchSplitRooms) {
      <div class="split-rooms">
        <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="splitRooms" (change)="changeSplitRooms()">
            Разделить на два номера
          </label>
        </div>
      </div>
    }
  </div>

  <div class="modal-footer">
    <button type="button"
            class="btn-recalc"
            [disabled]="!form.valid"
            (click)="submit()">Пересчитать
    </button>
  </div>
</div>
