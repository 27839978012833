<div appDraggable [dragHandle]="handle" class="container"
     [style.width.px]="width() - 100"
     [style.height.px]="height() - 100">
  <div #handle class="drag-handle"></div>
  <button (click)="closed.emit()" class="close-button" type="button">
    <img alt="" src="/assets/icons/close-icon.svg" />
  </button>

  @if (trustedUrl()) {
    <webview [style.width.px]="width() - 150"
             [style.height.px]="height() - 150"></webview>
  }
</div>
