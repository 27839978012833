import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

interface HotelVideoCategory {
  categoryId: number;
  previewUrl: string;
}

@Component({
  selector: 'app-search-result-map-info-stories',
  templateUrl: './search-result-map-info-stories.component.html',
  styleUrl: './search-result-map-info-stories.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchResultMapInfoStoriesComponent {
  tourId = input.required<string>();
  categoryIds = input.required<number[]>();
  openHotelModal = output<void>();

  categories = computed<HotelVideoCategory[]>(() => {
    const categoryIds = this.categoryIds();

    return categoryIds.map(categoryId => {
      const categoryImageUrl = this.getCategoryImageUrl(categoryId);
      const previewUrl = `assets/icons/search/hotel-video-categories/${categoryImageUrl}`;

      return {
        categoryId,
        previewUrl,
      } as HotelVideoCategory;
    });
  });

  private getCategoryImageUrl(categoryId: number): string {
    const maps = {
      1: 'building.png',
      2: 'room.png',
      3: 'beach.png',
      4: 'territory.png',
      5: 'pool.png',
      6: 'restaurant.png',
      8: 'promo.jpg',
    };

    return maps[categoryId] ?? 'promo.jpg';
  }
}
