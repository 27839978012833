import { DecimalPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-search-result-hotel-conversion',
  template: ` <span class="conversion-value" matTooltip="Конверсия в продажу">
    <img src="assets/icons/favorite-hotels/hotel-conversion.svg" />
    {{ value() * 100 | number: '1.0-0' }}%
  </span>`,
  styles: `
    .conversion-value {
      background-color: #007cf0;
      padding: 2px 4px 2px 6px;
      font-size: 11px;
      border-radius: 8px;
      color: #fff;
    }
  `,
  standalone: true,
  imports: [MatTooltip, DecimalPipe],
})
export class SearchResultHotelConversionComponent {
  value = input.required<number>();
}
