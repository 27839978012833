<div class="header-container">
  <div class="header-container-photo">
    <app-search-hotel-photo [hotelPhoto]="hotel.photo" />

    @if (searchTours.length && showExtControls()) {
      <div class="header-container-photo-control"
        matTooltip="Информация об отеле"
        (click)="showHotelInfoModal()">
        <span class="header-container-photo-control-icon">?</span>
      </div>
    }
  </div>
  <div class="header-container-main">
    <div class="header">
      <div class="header-left-aside">
        <app-hotel-stars [value]="hotel.stars" />
        @if (hotel.salesCount) {
          <app-hotel-sales-count [salesCount]="hotel.salesCount" />
        }
        @if (hotel.bookingRating) {
          <app-search-hotel-booking-rating [rating]="hotel.bookingRating" [link]="hotel.bookingLink" />
        }
        @if (mapPointerVisible) {
          <div class="hotel-map-pointer"
            matTooltip="Показать на карте"
            [class.on-map]="hotelOnMap"
            (click)="showHotelOnMap.emit(hotel.id)">
            <img src="assets/icons/search/hotel-map-pointer.svg">
          </div>
        }

        @if (tags.length) {
          <div class="tags-container">
            @for (tag of tags; track tag.tag.id) {
              <img [attr.src]="'assets/icons/favorite-hotels/tag-' + (tag.priority + 1)  +'.svg'"
                [matTooltip]="tag.tag.name + ' (' + tag.tag.usePercent + '%)'"
                class="tag">
            }
          </div>
        }
      </div>
      <div class="header-right-aside">
        @if (allowReCalculate && searchTours.length) {
          <app-search-hotel-recalc-btn
            [adults]="adults()"
            [childAges]="childAges()"
            (recalcTourists)="recalcTours($event)" />
        }
      </div>
    </div>
    <div class="sub-header">
      @if (searchTours.length) {
        <span class="hotel-name link" (click)="showHotelInfoModal()">{{ hotel.name }}</span>
      } @else {
        <span class="hotel-name">{{ hotel.name }}</span>
      }
      <span class="hotel-region">{{ hotel.region }}</span>
    </div>
  </div>
</div>

@if (searchTours.length) {
  <div class="tours-list">
    @for (tour of searchTours; track tour.id; let i = $index) {
      <app-search-tour [class.visible]="i < toursVisibleCount || isAllToursVisible"
        [tour]="tour"
        [emitRecalcTour$]="emitRecalcTour$"
        [initSearchRequest]="initSearchRequest"
        [contentCreatorType]="contentCreatorType"
        [tourSelectMode]="tourSelectMode"
        [showDiscount]="showDiscount"
        [showConversion]="showConversion"
        [showTourMenu]="showTourMenu"
        [showPrice]="showPrice"
        />
    }
  </div>
}

@if (initSearchRequest && searchTours.length === 0 && allowResearch) {
  <div class="allow-research-container" [class.flex-center]="!toursResearched">
    @if (toursResearched) {
      <span class="tours-not-found-label">Туры не найдены</span>
    }
    <button type="button" class="research" [disabled]="showSearchToursLoader" (click)="researchTours()">
      @if (showSearchToursLoader) {
        Ищем туры в отель...
      } @else {
        <img class="research-icon" src="assets/icons/favorite-hotels/btn-search-icon.svg">
        Найти туры в отель
      }
    </button>
  </div>
}

@if (searchTours.length > toursVisibleCount && !isAllToursVisible) {
  <div (click)="showAllTours()" class="more-tours">
    +{{ searchTours.length - 1 | pluralize:'тур,тура,туров' }}
  </div>
}

@if (showRecalcToursLoader) {
  <div class="search-tours-loader">
    <div class="search-tours-loader-label">
      Пересчитываем туры...
      <app-line-progress [animationDuration]="'30s'" />
    </div>
    <img class="search-tours-loader-icon" src="assets/icons/search/hotel-search-tours-loader.svg">
  </div>
}

@if (showToursNotFound && !allowResearch) {
  <div class="tours-not-found">
    <span class="tours-not-found-label">Туры не найдены</span>
  </div>
}

