<div class="container">
  <div class="label">Сортировка</div>
  <div class="items">
    @for (sort of variants; track sort) {
      <button
        (click)="selectVariant(sort)"
        [class.active]="sort === activeSort"
        type="button"
        class="item">
        {{ sort | SearchResultGroupsSortPipe }}
      </button>
    }
  </div>
</div>
