@if (freights().airlines) {
  <div class="airlines">
    @for (airline of freights().airlines; track airline.code) {
      <img [src]="'https://ht.kz/img/airlines/' + airline.code + '.png'" [alt]="airline.code" class="airline-logo">
    }
  </div>
}

@if (freights().seats) {
  <div class="seats">
    <span class="seats-label">Эконом</span>
    <div class="directions">
      <app-andromeda-state-show-tour-seats [label]="freights().seats.economy.forward | seatTourFlightSeatsAvailable"
                                           [value]="freights().seats.economy.forward" />
      <app-andromeda-state-show-tour-seats [label]="freights().seats.economy.backward | seatTourFlightSeatsAvailable"
                                           [value]="freights().seats.economy.backward" />
    </div>
  </div>

  @if (freights().seats.business) {
    <div class="seats">
      <span class="seats-label">Бизнес</span>
      <div class="directions">
        <app-andromeda-state-show-tour-seats [label]="freights().seats.business.forward | seatTourFlightSeatsAvailable"
                                             [value]="freights().seats.business.forward" />
        <app-andromeda-state-show-tour-seats [label]="freights().seats.business.backward | seatTourFlightSeatsAvailable"
                                             [value]="freights().seats.business.backward" />
      </div>
    </div>
  }
}

@if (departureTimes().length) {
  <div class="departure-times">{{ departureTimes().join(', ') }}</div>
}
