import { Pipe, PipeTransform } from '@angular/core';
import { SearchResultSortDirection } from '../../search-tours.model';

@Pipe({
  name: 'searchResultsGroupsSort',
  standalone: true,
})
export class SearchResultGroupsSortPipe implements PipeTransform {
  transform(value: SearchResultSortDirection, ...args: any[]): any {
    switch (value) {
      case SearchResultSortDirection.salesCount:
        return 'По продажам';
      case SearchResultSortDirection.priceAsc:
        return 'По цене';
      case SearchResultSortDirection.discountDesc:
        return 'По скидке';
      case SearchResultSortDirection.hotelRating:
        return 'По рейтингу';
      case SearchResultSortDirection.conversion:
        return 'По конверсии';
      default:
        return value;
    }
  }
}
