<div class="claim-loader-container">
  <div class="text-center">
    <label class="text-label">Вы выбрали GDS тур, ищем подходящие авиаперелеты</label>
  </div>
  <div class="progress">
    <app-line-progress [animationDuration]="'20s'" />
  </div>

  @if (showWaitMoreTime()) {
    <p class="wait-more">Туроператор отвечает немного дольше чем обычно, подождите еще чуть чуть.</p>
  }
</div>
