import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function childAgesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value === null || value === undefined || value === '') {
      return null; // пустое значение не должно считаться ошибкой
    }

    if (isNaN(value)) {
      return { notNumber: true };
    }

    const numericValue = Number(value);
    if (numericValue > 15) {
      return { max: { maxValue: 15, actualValue: numericValue } };
    }

    return null;
  };
}
