import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../../environments/environment';
import { apiResponsePipe } from '../../../../api-response.pipe';

const SEARCH_FORM_CURRENCY = 'kzt';
const SEARCH_FORM_TYPE = 'manager';
const SEARCH_FORM_EXPAND = ['form.airlines-list', 'form.tour-operators-list'];

@Injectable({ providedIn: 'root' })
export class SearchApiService {
  private cacheSearchFormParams$: Observable<SearchFormParams>;

  constructor(private readonly http: HttpClient) {}

  loadFormParams$(useCache = true): Observable<SearchFormParams> {
    if (useCache && this.cacheSearchFormParams$) {
      return this.cacheSearchFormParams$;
    }

    if (!this.cacheSearchFormParams$) {
      let queryParams = new HttpParams();
      queryParams = queryParams.append('currency', `${SEARCH_FORM_CURRENCY}`);
      queryParams = queryParams.append('type', `${SEARCH_FORM_TYPE}`);
      queryParams = queryParams.append('expand', `${SEARCH_FORM_EXPAND}`);
      this.cacheSearchFormParams$ = this.http
        .get<ApiResponse<SearchFormParams>>(`${AppConfig.apiUrl}/search-form/params`, {
          params: queryParams,
        })
        .pipe(
          apiResponsePipe,
          map(formParams => {
            formParams.countries = formParams.countries.sort((c1, c2) => c1.position - c2.position);

            return formParams;
          }),
          shareReplay(1),
        );
    }

    return this.cacheSearchFormParams$;
  }

  warmUpSearchFormParamsCache(): Observable<SearchFormParams> {
    const useCache = false;

    return this.loadFormParams$(useCache);
  }
}
