import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { SearchResultMapInfoWindowTourFlightsComponent } from '../../../../deals/modules/deal-view/modules/search-tours/components/search-result/components/map/info-window/flights/search-result-map-info-window-tour-flights.component';
import { SearchResultsResponseTour } from '../../../services/search/websocket-tours.model';

@Component({
  selector: 'app-search-tour-flights-popup',
  template: ` <div class="flights-container">
    <button (click)="closePopup.emit()" class="close-button" type="button">
      <img src="/assets/icons/close-icon.svg" />
    </button>
    <h3 class="flights-label">Информация по перелетам</h3>
    <app-search-result-map-info-window-tour-flights [tour]="tour()" />
  </div>`,
  styles: `
    .flights-container {
      width: 340px;
      padding: 16px;
      border-radius: 12px;
      background-color: #fff;
      position: relative;
    }

    .flights-label {
      margin-bottom: 16px;
    }

    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchResultMapInfoWindowTourFlightsComponent],
})
export class SearchTourFlightsPopupComponent {
  tour = input.required<SearchResultsResponseTour>();
  closePopup = output<void>();
}
