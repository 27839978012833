import {
  InitSearchRequestOptionsGroupResult,
  SearchResultsResponseTour,
  StorageSearchGroupResult,
} from '../services/search/websocket-tours.model';

export function convertToursToStorageSearchGroupResult(
  tours: SearchResultsResponseTour[],
  groupResults: InitSearchRequestOptionsGroupResult,
): StorageSearchGroupResult {
  const results: StorageSearchGroupResult = {};

  for (const tour of tours) {
    if (groupResults === InitSearchRequestOptionsGroupResult.byHotel) {
      if (results.hasOwnProperty(tour.hotel.id)) {
        const currentTour = results[tour.hotel.id][0];
        if (currentTour.price.value > tour.price.value) {
          results[tour.hotel.id] = [tour];
        }
      } else {
        results[tour.hotel.id] = [tour];
      }
    } else {
      if (results.hasOwnProperty(tour.hotel.id)) {
        results[tour.hotel.id].push(tour);
        results[tour.hotel.id].sort((a, b) => a.brandPrice.value - b.brandPrice.value);
      } else {
        results[tour.hotel.id] = [tour];
      }
    }
  }

  return results;
}
