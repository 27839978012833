import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { timer } from 'rxjs';
import { LineProgressComponent } from '../../../../../../ui-components/line-progress/line-progress.component';

@Component({
  selector: 'app-andromeda-state-loading-gds-claim',
  templateUrl: './andromeda-state-loading-gds-claim.component.html',
  styleUrl: './andromeda-state-loading-gds-claim.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LineProgressComponent],
})
export class AndromedaStateLoadingGdsClaimComponent implements OnInit {
  timout = 10_000;
  showWaitMoreTime = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    timer(this.timout)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.showWaitMoreTime.set(true);
      });
  }
}
