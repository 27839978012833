import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { SearchResultSortDirection as SortDirection } from '../../../search-tours.model';

@Component({
  selector: 'app-search-result-groups-sorts-dropdown',
  templateUrl: './search-result-groups-sorts-dropdown.component.html',
  styleUrl: './search-result-groups-sorts-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchResultGroupsSortsDropdownComponent {
  activeSort: SortDirection;
  selected = output<SortDirection>();

  get variants(): SortDirection[] {
    return [
      SortDirection.priceAsc,
      SortDirection.hotelRating,
      SortDirection.salesCount,
      SortDirection.conversion,
    ];
  }

  selectVariant(sort: SortDirection) {
    this.selected.emit(sort);
  }
}
