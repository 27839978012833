<app-andromeda-claim-provider [claimDocument]="claimDocument()" />

@if (hotels().length === 1) {
  <app-andromeda-claim-hotels [hotels]="hotels()"
                              [variants]="hotelsVariants()"
                              [form]="hotelsControl" />
} @else {
  <app-andromeda-claim-hotels-combi [hotelGroups]="hotelGroups"
                                    [form]="hotelsControl" />
}

@if (transports().length || transportsVariants().length) {
  @if (isGDS()) {
    <app-andromeda-claim-flights-gds [flightGroups]="flightGroups"
                                     [form]="flightsControl" />
  } @else {
    <app-andromeda-claim-flights [flightGroups]="flightGroups"
                                 [form]="flightsControl" />
  }
}

@if (transferServices().length) {
  <app-andromeda-claim-services label="Услуги трансфера"
                                [services]="transferServices()"
                                [form]="servicesControl" />
}

@if (insuranceServices().length) {
  <app-andromeda-claim-services label="Услуги страховки"
                                [services]="insuranceServices()"
                                [form]="servicesControl" />
}

@if (otherServices().length) {
  <app-andromeda-claim-services label="Другие услуги"
                                [services]="otherServices()"
                                [form]="servicesControl"
                                [groups]="groups()"
                                [showFilters]="true" />
}

<app-andromeda-claim-tourists [tourists]="tourists()"
                              [availableAdditionalInfant]="availableAdditionalInfant()"
                              [additionalInfantChecked]="additionalInfantChecked()" />

@if (spoMessage()) {
  <app-andromeda-claim-spo [message]="spoMessage()" />
}

@if (message()) {
  <app-andromeda-claim-message [message]="message()" />
}

@if (gdsTransport()) {
  <app-andromeda-claim-flight-gds-tariff [claimDocument]="claimDocument()" />
}

@if (apiErrorResponse()) {
  <div class="alert-container">
    <app-alert-label [type]="alertLabelType.danger">
      <app-andromeda-error [apiError]="apiErrorResponse()" />
    </app-alert-label>
  </div>
}

<app-andromeda-claim-price [initClaim]="initClaim()"
                           [claim]="claim()"
                           [showPrice]="showPrice()" />

@if (applyChangeInProgress()) {
  <div class="apply-changes-overlay"></div>
}
