<div class="container">
  <div class="form-group">
    <input
      #searchQuery
      (input)="onSearchUpdated(searchQuery.value)"
      type="text"
      class="form-control"
      appAutofocus
      placeholder="Поиск по потребностям"
    />
  </div>

  <div class="items">
    @if (isLoading()) {
      Загружаем теги...
    }
    @for (item of visibleItems(); track item.id) {
      <div class="checkbox" [class.disabled]="disabled() && !item.selected">
        <label>
          <div class="label-group">
            <input
              type="checkbox"
              [(ngModel)]="item.selected"
              (change)="onCheckboxChange(item.id, $event.target.checked)"
            />
            {{ item.name }}
          </div>
        </label>
      </div>
    }
  </div>

  <div class="controls">
    <button type="button" class="btn btn-reset" (click)="reset()">Сбросить</button>
    <button type="button" class="btn btn-apply" (click)="apply()">Применить</button>
  </div>
</div>
