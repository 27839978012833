<div class="bg" (click)="close()"></div>

<div #customComponentContainer class="custom-component-container"></div>

@if (showToursDropArea) {
  <div
    class="tour-drop-area-container">
    <div dndDropzone
      (dndDrop)="onDropTour($event)"
      class="tour-drop-area">
      <span>+ Перенести тур сюда</span>
    </div>
  </div>
}

@if (showCloseButton) {
  <div
    (click)="close()"
    class="close">
    <button type="button" class="btn-close">
      <img src="assets/icons/deal-view/dd-close.svg">
    </button>
  </div>
}
