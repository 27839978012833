@switch (currentViewState()) {
  @case (viewState.showLoader) {
    <app-andromeda-state-show-loader />
  }
  @case (viewState.showTours) {
    <app-andromeda-state-show-tours [initTour]="initTour()"
                                    [foundedTours]="foundTours"
                                    (selectedTour)="setSelectedTour($event)" />
  }
  @case (viewState.showClaimLoader) {
    <app-andromeda-state-loading-claim />
  }
  @case (viewState.showClaimGdsLoader) {
    <app-andromeda-state-loading-gds-claim />
  }
  @case (viewState.showClaim) {
    @if (foundTours.length) {
      <app-andromeda-btn-back-to-tours (click)="showToursList()" />
    }

    <div class="view-claim-container">
      <app-andromeda-state-show-claim [initTour]="initTour()"
                                      [selectedTour]="selectedTour()"
                                      [initClaim]="selectedClaim()"
                                      [isGDS]="isGDS()"
                                      [worker]="currentWorker" />
    </div>
  }
  @case (viewState.showClaimNotLoaded) {
    <app-andromeda-btn-back-to-tours (click)="showToursList()" />
    <app-andromeda-error [label]="'Ошибка при загрузке заявки'"
                         [apiError]="apiErrorResponse()" />
  }
}
