export function calculateDaysBetween(startDate: Date, endDate: Date) {
  const oneDay = 1000 * 60 * 60 * 24;
  const start = startDate.getTime();
  const end = endDate.getTime();

  return Math.ceil((end - start) / oneDay);
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
}

export function minusDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);

  return result;
}
