import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

interface ErrorValidation {
  errors: string[];
  field: string;
}

interface ErrorNotFound {
  message: string;
}

@Component({
  selector: 'app-andromeda-error',
  templateUrl: './andromeda-error.component.html',
  styleUrl: './andromeda-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AndromedaErrorComponent {
  label = input<string>();
  apiError = input<HttpErrorResponse>();

  errorMessage = computed<string>(() => {
    if (this.apiError().status === 422) {
      const errors: ErrorValidation[] = this.apiError().error;
      return errors.map(error => error.errors.join('<br>')).join('<br>');
    }

    if (this.apiError().status === 404) {
      const error: ErrorNotFound = this.apiError().error;

      return error.message;
    }

    return '';
  });
}
