<div class="andromeda-container">
  <h4>Состав туристов</h4>
  @for (tourist of tourists; track tourist.key) {
    <p>
      {{ tourist.age | touristAge }}
    </p>
  }

  @if (availableAdditionalInfant) {
    <div class="andromeda-infant-container">
      <div class="checkbox">
        <label>
          <input type="checkbox"
                 [checked]="additionalInfantChecked"
                 (click)="toggleAdditionalInfant()">
          Дополнительно младенец
        </label>
      </div>
    </div>
  }
</div>
