import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-andromeda-claim-spo',
  templateUrl: './andromeda-claim-spo.component.html',
  styleUrls: ['./andromeda-claim-spo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AndromedaClaimSpoComponent {
  message = input.required<string>();
}
