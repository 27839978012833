import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  OnChanges,
  OnInit,
  signal,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AndromedaUiService } from '../../../../services/andromeda-ui.service';
import { Group, Service } from '../../../../services/andromeda.model';

@Component({
  selector: 'app-andromeda-claim-services',
  templateUrl: './andromeda-claim-services.component.html',
  styleUrls: ['./andromeda-claim-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule],
})
export class AndromedaClaimServicesComponent implements OnInit, OnChanges {
  label = input.required<string>();
  services = input.required<Service[]>();
  form = input.required<FormGroup>();
  groups = input<Group[]>([]);
  showFilters = input<boolean>(false);

  filters = computed<Group[]>(() => {
    if (!this.showFilters() || !this.groups().length) {
      return [];
    }

    const filters: Group[] = [];
    this.services().forEach(service => {
      const existGroup = filters.find(group => group.id === service.groupId);
      if (!existGroup) {
        const serviceGroup = this.groups().find(group => group.id === service.groupId);
        if (serviceGroup) {
          filters.push(serviceGroup);
        }
      }
    });

    return filters;
  });

  activeFilters = computed<string[]>(() => {
    const activeFilters: string[] = [];
    this.services().forEach(service => {
      if (
        (service.required === 'true' || service.name.match(/(багаж)/i)) &&
        activeFilters.indexOf(service.groupId) === -1
      ) {
        activeFilters.push(service.groupId);
      }
    });

    return activeFilters;
  });

  selectedFilters = signal<string[]>([]);

  constructor(private readonly uiService: AndromedaUiService) {}

  ngOnInit(): void {
    this.selectedFilters.set(this.activeFilters());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.groups) {
      this.selectedFilters.set(this.activeFilters());
    }
  }

  extractNote(service: Service): string | null {
    return service?.note[0]._ || null;
  }

  toggleActive(groupId: string): void {
    const selectedFilters = this.selectedFilters();
    const index = selectedFilters.indexOf(groupId);
    if (index === -1) {
      selectedFilters.push(groupId);
    } else {
      selectedFilters.splice(index, 1);
    }

    this.selectedFilters.set(selectedFilters);
  }

  isActive(groupId: string): boolean {
    return this.selectedFilters().includes(groupId);
  }

  changeValue(event: any, serviceUid: string) {
    const isChecked = event.currentTarget.checked;

    const newUid = isChecked ? serviceUid : '';
    const oldUid = isChecked ? '' : serviceUid;

    this.uiService.changeService(newUid, oldUid);
  }
}
