import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BrxButtonComponent } from '../../../../../../../../ui-components/hermes/button/brx-button.component';
import { LineProgressComponent } from '../../../../../../../../ui-components/line-progress/line-progress.component';
import { PriceCurrencyPipe } from '../../../../../../pipes/price-currency.pipe';
import { AndromedaUiService } from '../../../../services/andromeda-ui.service';
import { Claim } from '../../../../services/andromeda.model';

@Component({
  selector: 'app-andromeda-claim-price',
  templateUrl: './andromeda-claim-price.component.html',
  styleUrl: './andromeda-claim-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BrxButtonComponent, DecimalPipe, PriceCurrencyPipe, LineProgressComponent],
})
export class AndromedaClaimPriceComponent {
  initClaim = input.required<Claim>();
  claim = input.required<Claim>();
  showPrice = input.required<boolean>();

  showCommission = signal<boolean>(false);

  initPriceValue = computed<number>(() => {
    return this.initClaim().claimDocument[0]?.moneys[0]?.money[0].discoCost;
  });

  initPriceCurrency = computed<string>(() => {
    return this.initClaim().claimDocument[0]?.moneys[0]?.money[0].currency;
  });

  initBrandPriceValue = computed<number>(() => {
    return this.initClaim().claimDocument[0]?.buyerMoneys[0]?.buyerClaimMoney[0].price;
  });

  priceValue = computed<number>(() => {
    return this.claim().claimDocument[0]?.moneys[0]?.money[0].discoCost;
  });

  priceCurrency = computed<string>(() => {
    return this.claim().claimDocument[0]?.moneys[0]?.money[0].currency;
  });

  brandPriceValue = computed<number>(() => {
    return this.claim().claimDocument[0]?.buyerMoneys[0]?.buyerClaimMoney[0].price;
  });

  commissionValue = computed<number>(() => {
    const money = this.claim().claimDocument[0]?.moneys[0]?.money[0];
    return money.discoCost - money.net;
  });

  commissionPercent = computed<number>(() => {
    return Number(this.claim().claimDocument[0].comissionPercent);
  });

  calculateInProgress = toSignal(this.uiService.calculateInProgress$);

  constructor(private readonly uiService: AndromedaUiService) {}

  calculate(): void {
    this.uiService.calculate();
  }

  toggleCommission(): void {
    this.showCommission.set(!this.showCommission());
  }
}
