<div appDraggable [dragHandle]="handle"  class="check-price-container">
  <div #handle class="drag-handle"></div>

  <div class="header">Онлайн бронирование тура</div>
  <button (click)="closePopup.emit()" class="close-button" type="button">
    <img src="/assets/icons/close-icon.svg" />
  </button>

  <app-search-tour-andromeda-container [initTour]="tour()" />
</div>
