import { DatePipe, KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-andromeda-claim-flights-gds',
  templateUrl: './andromeda-claim-flights-gds.component.html',
  styleUrls: ['./andromeda-claim-flights-gds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, KeyValuePipe, DatePipe],
})
export class AndromedaClaimFlightsGdsComponent {
  form = input.required<FormGroup>();
  flightGroups = input.required<any>({});

  flightGroupsOrder(a: any, b: any): any {
    return a;
  }
}
