import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ComponentRef, input, output } from '@angular/core';
import { WebviewSupportService } from '../../../../../../core/services';
import { ElectronService } from '../../../../../../core/services/electron.service';
import { PopupService } from '../../../../../../shared/services/popup-service.service';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../../ui-components/alert-label/alert-label.component';
import { BrxButtonComponent } from '../../../../../../ui-components/hermes/button/brx-button.component';
import { BrowserComponent } from '../../../../../browser/browser.component';
import { SearchTourLabelCombinedComponent } from '../../../../components/tour/label-combi/search-tour-label-combined.component';
import { SearchTourLabelGdsComponent } from '../../../../components/tour/label-gds/search-tour-label-gds.component';
import { PriceCurrencyPipe } from '../../../../pipes/price-currency.pipe';
import { SearchResultsResponseTour } from '../../../../services/search/websocket-tours.model';
import { AndromedaStateShowTourAirlinesComponent } from './airlines/andromeda-state-show-tour-airlines.component';
import { AndromedaStateShowTourPriceDiffComponent } from './price-diff/andromeda-state-show-tour-price-diff.component';

@Component({
  selector: 'app-andromeda-state-show-tours',
  templateUrl: './andromeda-state-show-tours.component.html',
  styleUrl: './andromeda-state-show-tours.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [PopupService],
  imports: [
    BrxButtonComponent,
    AlertLabelComponent,
    DecimalPipe,
    PriceCurrencyPipe,
    AndromedaStateShowTourAirlinesComponent,
    SearchTourLabelGdsComponent,
    SearchTourLabelCombinedComponent,
    AndromedaStateShowTourPriceDiffComponent,
  ],
})
export class AndromedaStateShowToursComponent {
  initTour = input.required<SearchResultsResponseTour>();
  foundedTours = input<SearchResultsResponseTour[]>();
  selectedTour = output<SearchResultsResponseTour>();

  alertLabelType = AlertLabelType;

  private browserRef: ComponentRef<BrowserComponent>;

  constructor(
    private readonly electronService: ElectronService,
    private readonly webviewSupportService: WebviewSupportService,
    private readonly datePipe: DatePipe,
    private readonly popupService: PopupService,
  ) {}

  openBookUrl(): void {
    const link = this.initTour().bookURL;
    if (this.webviewSupportService.isWebviewSupported()) {
      this.browserRef = this.popupService.showPopup(BrowserComponent);

      this.browserRef.setInput('url', this.initTour().bookURL);
      this.browserRef.instance.closed.subscribe(() => {
        this.popupService.closeAllModals();
      });
    } else {
      this.electronService.shell.openExternal(link, { activate: true }).then();
    }
  }

  get initTourSummary(): string {
    const date = this.datePipe.transform(this.initTour().date, 'dd.MM');
    let tourists = `${this.initTour().tourists.adults} взр`;
    if (this.initTour().tourists.childAges?.length) {
      const childCount = this.initTour().tourists.childAges.length;
      const childAges = this.initTour().tourists.childAges.join(',');
      tourists += ` ${childCount} (${childAges})`;
    }

    return `Выбранный тур: ${date} на ${this.initTour().nights} н, вылет из ${this.initTour().departCity.name}, ${tourists}, ${this.initTour().operator.code}`;
  }
}
