const createInfoWindow = ({ OverlayView = google.maps.OverlayView, ...args }) => {
  class InfoWindowOverlay extends google.maps.OverlayView {
    private div!: HTMLDivElement;
    private clickOutsideHandler!: (event: MouseEvent) => void;

    constructor(
      private position: google.maps.LatLng,
      private content: HTMLElement,
    ) {
      super();
    }

    onAdd(): void {
      // Создаем div элемент для popup
      this.div = document.createElement('div');
      this.div.style.position = 'absolute';
      this.div.appendChild(this.content);

      this.div.addEventListener('click', (event: MouseEvent) => {
        // Останавливаем всплытие события, чтобы оно не дошло до маркера
        event.stopPropagation();
      });

      // Добавляем элемент на карту
      const panes = this.getPanes();
      if (panes) {
        panes.floatPane.appendChild(this.div);
      }

      this.clickOutsideHandler = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (this.div && !this.div.contains(target)) {
          this.close();
        }
      };
      document.querySelector('.map-container').addEventListener('mousedown', this.clickOutsideHandler);
    }

    draw(): void {
      const overlayProjection = this.getProjection();
      const position = overlayProjection.fromLatLngToDivPixel(this.position);

      if (position && this.div) {
        const divRect = this.div.getBoundingClientRect();
        const divWidth = divRect.width;
        const divHeight = divRect.height;

        this.div.style.left = position.x - divWidth / 2 + 'px';
        this.div.style.top = position.y - divHeight / 2 + 'px';

        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        const updatedDivRect = this.div.getBoundingClientRect();

        const spaceBelow = windowHeight - updatedDivRect.bottom;
        const spaceAbove = updatedDivRect.top;
        const spaceRight = windowWidth - updatedDivRect.right;
        const spaceLeft = updatedDivRect.left;

        if (spaceBelow < 0 && spaceAbove > divHeight) {
          this.div.style.top = position.y - divHeight / 2 - divHeight + 'px';
        } else if (spaceBelow < 0) {
          this.div.style.top = Math.max(windowHeight - divHeight, 0) + 'px';
        }

        if (spaceRight < 0 && spaceLeft > divWidth) {
          this.div.style.left = position.x - divWidth / 2 - divWidth + 'px';
        } else if (spaceRight < 0) {
          this.div.style.left = Math.max(windowWidth - divWidth, 0) + 'px';
        }

        if (spaceLeft < 0) {
          this.div.style.left = '0px';
        }

        if (spaceAbove < 0) {
          this.div.style.top = '0px';
        }
      }
    }

    onRemove(): void {
      if (this.div) {
        this.div.parentNode?.removeChild(this.div);
        this.div = null!;
      }

      document.querySelector('.map-container')?.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    close(): void {
      this.onRemove();
    }
  }

  const { position, content } = args;

  return new InfoWindowOverlay(position, content);
};

export default createInfoWindow;
