import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ClaimDocument } from '../../../../services/andromeda.model';

@Component({
  selector: 'app-andromeda-claim-flight-gds-tariff',
  templateUrl: './andromeda-claim-flight-gds-tariff.component.html',
  styleUrls: ['./andromeda-claim-flight-gds-tariff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AndromedaClaimFlightGdsTariffComponent {
  claimDocument = input.required<ClaimDocument>();

  show = false;

  toggle(): void {
    this.show = !this.show;
  }

  get tariff(): string {
    const allTariff = this.claimDocument().gdsTransport || [];
    const tariff = allTariff.length > 0 ? allTariff[allTariff.length - 1].message[0]._ : '';

    return tariff.replace(/\n/g, '<br>').replace(/\t/, '&nbsp;&nbsp;&nbsp;&nbsp;');
  }
}
