import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { PluralizePipe } from '../../../../../helpers/pipes/plural/pluralize.pipe';
import { DraggableDirective } from '../../../../../shared/directives/draggable.directive';
import { SafeHtmlPipe } from '../../../../../shared/pipes/safe-html.pipe';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../ui-components/alert-label/alert-label.component';
import { LineProgressComponent } from '../../../../../ui-components/line-progress/line-progress.component';
import { ManagerCommentsComponent } from '../../../../content-creator/components/items/manager-comments/manager-comments.component';
import { PhotosComponent } from '../../../../content-creator/components/items/photos/photos.component';
import { ReviewsComponent } from '../../../../content-creator/components/items/reviews/reviews.component';
import { VideosComponent } from '../../../../content-creator/components/items/videos/videos.component';
import { ApiContentType } from '../../../../content-creator/interfaces/content-creator.interface';
import { ContentCreatorApiService } from '../../../../content-creator/services/content-creator-api.service';
import { ContentCreatorSizesService } from '../../../../content-creator/services/content-creator-sizes.service';
import { SearchBookingRatingComponent } from '../hotel-booking-rating/search-hotel-booking-rating.component';
import { SearchHotelStarsComponent } from '../hotel-stars/search-hotel-stars.component';

export enum Tabs {
  Description = 'description',
  Media = 'media',
  Reviews = 'reviews',
  ManagerComments = 'managerComments',
}

@Component({
  selector: 'app-search-hotel-modal',
  templateUrl: './search-hotel-modal.component.html',
  styleUrl: './search-hotel-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SearchHotelStarsComponent,
    SearchBookingRatingComponent,
    PluralizePipe,
    SafeHtmlPipe,
    ReviewsComponent,
    PhotosComponent,
    VideosComponent,
    LineProgressComponent,
    AlertLabelComponent,
    DraggableDirective,
    ManagerCommentsComponent,
  ],
  providers: [ContentCreatorSizesService],
})
export class SearchHotelModalComponent implements OnInit {
  defaultTab = input<Tabs>(Tabs.Media);
  hotelId = input.required<number>();
  tourId = input.required<string>();
  tagIds = input<number[]>([]);

  closeModal = output<void>();

  showLoader = signal<boolean>(true);
  showError = signal<boolean>(false);
  tourContent = signal<TourContent>(undefined);
  isShowFullDescription = signal<boolean>(false);
  selectedTab = signal<Tabs>(undefined);

  activeTab = computed<Tabs>(() => {
    const defaultTab = this.defaultTab();
    const selectedTab = this.selectedTab();

    return selectedTab ? selectedTab : defaultTab;
  });

  tabs = Tabs;
  alertTypes = AlertLabelType;

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly contentCreatorApiService: ContentCreatorApiService,
  ) {}

  ngOnInit() {
    const tourContent$ = this.contentCreatorApiService.getTourContent(this.tourId(), [
      ApiContentType.HotelReviews,
      ApiContentType.HotelVideos,
      ApiContentType.HotelPhotos,
      ApiContentType.TourTextMessageData,
      ApiContentType.HotelParams,
      ApiContentType.HotelManagerComments,
    ]);

    tourContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: tourContent => {
        this.tourContent.set(tourContent);
        this.showLoader.set(false);
        this.cdRef.detectChanges();
      },
      error: () => {
        this.showError.set(true);
        this.showLoader.set(false);
        this.cdRef.detectChanges();
      },
    });
  }

  changeTab(tab: Tabs): void {
    this.selectedTab.set(tab);
  }

  showFullDescription(): void {
    this.isShowFullDescription.set(true);
    this.cdRef.detectChanges();
  }

  getParamIcon(paramId: number): string {
    const maps = {
      1: 'outdoor.svg',
      2: 'sport.svg',
      745: 'sport.svg',
      3: 'meal.svg',
      4: 'internet.svg',
      5: 'parking.svg',
      7: 'general.svg',
      8: 'language.svg',
      322: 'reception.svg',
      325: 'entertainment.svg',
      323: 'cleaning.svg',
      324: 'business.svg',
      337: 'security.svg',
      397: 'accessibility.svg',
      798: 'swimming-pool.svg',
      941: 'swimming-pool.svg',
      942: 'swimming-pool.svg',
      590: 'wellness.svg',
      1132: 'language.svg',
    };

    if (maps[paramId]) {
      return `assets/icons/search/hotel-params/${maps[paramId]}`;
    }
    return '';
  }
}
