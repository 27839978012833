import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ClaimDocument, Provider } from '../../../../services/andromeda.model';

@Component({
  selector: 'app-andromeda-claim-provider',
  templateUrl: './andromeda-claim-provider.component.html',
  styleUrl: './andromeda-claim-provider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AndromedaClaimProviderComponent {
  claimDocument = input.required<ClaimDocument>();

  momentConfirm = computed<boolean>(() => {
    return this.claimDocument()?.hotelMomentConfirm === '1';
  });

  allowPrebooking = computed<boolean>(() => {
    return this.claimDocument()?.enableDraftClaim === '1';
  });

  provider = computed<Provider>(() => {
    return this.claimDocument().provider[0];
  });
}
