import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotelRoom } from '@api-clients/api-client';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable()
export class SearchTourRoomsService {
  constructor(private readonly http: HttpClient) {}

  getHotelRooms(hotelId: number): Observable<HotelRoom[]> {
    const url = `${AppConfig.apiUrl}/hotel/rooms`;
    const params = new HttpParams().set('hotelId', hotelId);

    return this.http.get(url, { params }).pipe(apiResponsePipe);
  }
}
