import { WebsocketToursSearchService } from '../services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestParamsCombined,
  InitSearchRequestType,
  SearchResultsResponseTour,
} from '../services/search/websocket-tours.model';

export function createInitSearchRequestFromTour(tour: SearchResultsResponseTour): InitSearchRequest {
  return {
    id: WebsocketToursSearchService.generateId(),
    method: InitSearchRequestMethod.search,
    params: {
      params: {
        adults: tour.tourists.adults,
        childrenAges: tour.tourists.childAges,
        splitRooms: false,
        combined: tour.isCombined
          ? InitSearchRequestParamsCombined.onlyCombined
          : InitSearchRequestParamsCombined.notCombined,
        countryId: tour.country.id,
        dateFrom: tour.date,
        dateTo: tour.date,
        departCityId: tour.departCity.id,
        nightsFrom: tour.nights,
        nightsTo: tour.nights,
        notGDS: !tour.isGDS,
        onlyHotels: false,
        operators: [tour.operator.id],
        hotels: [tour.hotel.id],
      },
      options: {
        type: InitSearchRequestType.main,
        groupResults: InitSearchRequestOptionsGroupResult.noGroup,
        allowParallel: true,
      },
    },
  };
}
