import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MODAL_VERTICAL_MARGIN, MODAL_VERTICAL_PADDING } from '../constants/content-creator-sizes.constants';

/**
 * Сервис для хранения размеров элементов в ContentCreator.
 * Сервис не в руте, потому что у каждого создателя контента он свой
 */
@Injectable()
export class ContentCreatorSizesService {
  public readonly modalHeaderHeight = 38;
  public readonly hotelTitleHeight = 50;
  // Устанавливается при первом изменении блока
  private messageSendControlsHeightSubject = new BehaviorSubject<number>(0);
  messageSendControlsHeight$ = this.messageSendControlsHeightSubject.asObservable();

  private reservedHeightSubject = new BehaviorSubject<number>(0);
  reservedHeight$ = this.reservedHeightSubject.asObservable();

  constructor() {
    this.calculateReservedHeight();
  }

  public setMessageSendControlsHeight(height: number) {
    this.messageSendControlsHeightSubject.next(height);
    this.calculateReservedHeight();
  }

  private calculateReservedHeight() {
    this.reservedHeightSubject.next(
      MODAL_VERTICAL_MARGIN * 2 +
        MODAL_VERTICAL_PADDING * 2 +
        this.modalHeaderHeight +
        this.hotelTitleHeight +
        this.messageSendControlsHeightSubject.value +
        // Резервируем немного больше места, чтобы точно не было скролла
        4,
    );
  }
}
