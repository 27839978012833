<div class="andromeda-container">
  <h4>Информация по тарифу GDS перелета</h4>
  <div class="provider-container"
       [class.show]="show"
       [innerHTML]="tariff ">
  </div>

  <span (click)="toggle()" [innerText]="show ? 'Скрыть детали' : 'Показать детали'"
        class="btn-toggle"></span>
</div>
