import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AndromedaUiService } from '../../../../services/andromeda-ui.service';
import { People } from '../../../../services/andromeda.model';
import { TouristAgePipe } from '../../pipes/tourist-age.pipe';

@Component({
  selector: 'app-andromeda-claim-tourists',
  templateUrl: './andromeda-claim-tourists.component.html',
  styleUrls: ['./andromeda-claim-tourists.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TouristAgePipe],
})
export class AndromedaClaimTouristsComponent {
  @Input() tourists!: People[];
  @Input() availableAdditionalInfant = false;
  @Input() additionalInfantChecked = false;

  constructor(private readonly uiService: AndromedaUiService) {}

  toggleAdditionalInfant(): void {
    this.uiService.toggleAdditionalInfant(!this.availableAdditionalInfant);
  }
}
