<div appDraggable [dragHandle]="handle" class="container" [class.short]="showLoader() || showError()">
  <div #handle class="drag-handle"></div>

  <button (click)="closeModal.emit()" class="close-button" type="button">
    <img alt="" src="/assets/icons/close-icon.svg" />
  </button>

  @if (showLoader()) {
    <div class="loader-container">
      <app-line-progress [animationDuration]="'5s'" />
    </div>
  }

  @if (showError()) {
    <div class="error-container">
      <app-alert-label [type]="alertTypes.danger">
        Не смогли загрузить данные по отелю, попробуйте позже
      </app-alert-label>
    </div>
  }

  @if (tourContent()) {
    <div class="header">
      <app-hotel-stars [value]="tourContent().tourMessageData.hotel.stars" />
      <div class="booking-rating">
        @if (tourContent().tourMessageData.hotel.rating) {
          <app-search-hotel-booking-rating [rating]="tourContent().tourMessageData.hotel.rating" />
        }
        @if (tourContent().tourMessageData.hotel.reviewsWithTextCount) {
          <span class="booking-reviews-count" (click)="changeTab(tabs.Reviews)">
            {{ tourContent().tourMessageData.hotel.reviewsWithTextCount | pluralize:'отзыв,отзыва,отзывов' }}
          </span>
        }
      </div>
    </div>

    <div class="sub-header">
      <h1 class="hotel-name">{{ tourContent().tourMessageData.hotel.name }}</h1>
      <span class="hotel-location">{{ tourContent().tourMessageData.country }}
        , {{ tourContent().tourMessageData.region }}</span>
    </div>

    <div class="tabs-header">
      <div class="tab-header" [class.active]="activeTab() === tabs.Media" (click)="changeTab(tabs.Media)">
        <div class="header-label">Медиа</div>
      </div>
      <div class="tab-header" [class.active]="activeTab() === tabs.Reviews" (click)="changeTab(tabs.Reviews)">
        <div class="header-label">Отзывы</div>
      </div>
      <div class="tab-header" [class.active]="activeTab() === tabs.Description" (click)="changeTab(tabs.Description)">
        <div class="header-label">Описание</div>
      </div>
      <div class="tab-header" [class.active]="activeTab() === tabs.ManagerComments"
           (click)="changeTab(tabs.ManagerComments)">
        <div class="header-label">Комментарии</div>
      </div>
    </div>

    <div class="modal-content">
      <div class="tabs-content" [style.overflow-y.hidden]="activeTab() === tabs.Reviews">
        @if (activeTab() === tabs.Description) {
          <div class="tab-content description">
            <div class="hotel-description-container" [class.full]="isShowFullDescription()">
              <div class="hotel-description">
                {{ tourContent().tourMessageData.hotel.description }}
              </div>

              @if (tourContent().tourMessageData.hotel.description > 900 && !isShowFullDescription()) {
                <button type="button" class="show-more-text" (click)="showFullDescription()">
                  Показать весь текст <img src="assets/icons/favorite-hotels/chevron-down-hover.svg" />
                </button>
              }
            </div>

            @if (tourContent().hotel?.params?.length) {
              <div class="hotel-params">
                <h2 class="hotel-params-label">Информация об услугах</h2>
                <div class="params">
                  @for (param of tourContent().hotel.params; track param.values) {
                    <div class="param">
                      <div class="param-name">
                        <div class="param-name-label">
                          @if (getParamIcon(param.id)) {
                            <img class="param-name-icon" [src]="getParamIcon(param.id)">
                          }
                          <span>{{ param.name }}</span>
                        </div>
                      </div>
                      <div class="param-value">
                        <ul class="param-value-list">
                          @for (value of param.values; track value) {
                            <li class="param-value-list-item">
                              <span [innerHTML]="value.value | safeHtml"></span>
                              @if (value.paid) {
                                <img src="assets/icons/search/hotel-service-paid.svg">
                              }
                            </li>
                          }
                        </ul>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }

        @if (activeTab() === tabs.Media) {
          <div class="tab-content media">
            <h3 class="media-label">Фото</h3>
            <app-tour-content-photos [tourId]="tourId()" [tourContent]="tourContent()" [disableSelection]="true" />

            <h3 class="media-label">Видео</h3>
            <app-tour-content-videos [tourId]="tourId()" [tourContent]="tourContent()" [disableSelection]="true" />
          </div>
        }

        @if (activeTab() === tabs.Reviews) {
          <div class="tab-content reviews">
            <app-tour-content-reviews [tourId]="tourId()"
                                      [tourContent]="tourContent()"
                                      [initTagIds]="tagIds()"
                                      [appTextSelectionEnabled]="false" />
          </div>
        }

        @if (activeTab() === tabs.ManagerComments) {
          <div class="tab-content reviews">
            <app-tour-content-manager-comments [tourId]="tourId()"
                                               [tourContent]="tourContent()"
                                               [appTextSelectionEnabled]="false" />
          </div>
        }
      </div>
    </div>
  }
</div>
