@if (foundedTours().length) {
  <div class="container-tours">
    <table class="table-tours">
      <thead>
      <tr>
        <th>Размещение/питание</th>
        <th>Авиакомпания</th>
        <th>Цена у.е.</th>
        <th>Цена KZT</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th colspan="5">{{ initTourSummary }}</th>
      </tr>
        @for (tour of foundedTours(); track tour.id; let i = $index) {
          <tr>
            <td>
              <div class="room">
                @for (room of tour.rooms; track room.name) {
                  {{ room.name }} ({{ room.accommodation }})
                }
              </div>
              <div class="meal">
                {{ tour.meal.name }}

                @if (tour.meal.code) {
                  ({{ tour.meal.code }})
                }
              </div>
              <div class="ext-info">
                @if (tour.isGDS) {
                  <app-search-tour-label-gds />
                }
                @if (tour.isCombined) {
                  <app-search-tour-label-combined />
                }
              </div>
            </td>
            <td>
              <app-andromeda-state-show-tour-airlines [freights]="tour.freights" />
            </td>
            <td>
              {{ tour.price.value | number: '1.0-0' }} {{ tour.price.currency | priceCurrency }}
              @if (i > 0) {
                <br />
                <app-andromeda-state-show-tour-price-diff [oldPrice]="initTour().price.value"
                                                          [newPrice]="tour.price.value"
                                                          [currency]="tour.price.currency" />
              }
            </td>
            <td>
              {{ tour.brandPrice.value | number: '1.0-0' }} {{ tour.brandPrice.currency | priceCurrency }}
              @if (i > 0) {
                <br />
                <app-andromeda-state-show-tour-price-diff [oldPrice]="initTour().brandPrice.value"
                                                          [newPrice]="tour.brandPrice.value"
                                                          [currency]="tour.brandPrice.currency" />
              }
            </td>
            <td>
              <button brx-button color="yellow" type="button" (click)="selectedTour.emit(tour)">Состав тура</button>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
} @else {
  <div class="container-not-found">
    <app-alert-label [type]="alertLabelType.warning">
      Не удалось найти тур в системе онлайн бронирования
    </app-alert-label>

    @if (initTour().bookURL) {
      <p>Пока вы можете открыть тур в системе оператора.</p>
      <button brx-button color="blue" type="button" (click)="openBookUrl()">Открыть у оператора</button>
    }
  </div>
}
