import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AndromedaUiService {
  private calculateEventSub = new Subject<void>();
  private calculateInProgressSub = new BehaviorSubject<boolean>(false);
  private applyChangeInProgressSub = new BehaviorSubject<boolean>(false);
  private serviceChangedSubject = new Subject<string>();
  private flightChangedSubject = new Subject<{ newUid: string; oldUid: string }>();
  private hotelChangedSubject = new Subject<{ newUid: string; oldUid: string }>();
  private flightGdsChangedSubject = new Subject<{ uuid: string }>();
  private toggleAdditionalInfantSubject = new Subject<boolean>();

  applyChangeInProgress$ = this.applyChangeInProgressSub.asObservable();
  calculateInProgress$ = this.calculateInProgressSub.asObservable();
  calculateEvent$ = this.calculateEventSub.asObservable();
  serviceChanged$ = this.serviceChangedSubject.asObservable();
  flightChanged$ = this.flightChangedSubject.asObservable();
  hotelChanged$ = this.hotelChangedSubject.asObservable();
  flightGdsChanged$ = this.flightGdsChangedSubject.asObservable();
  toggleAdditionalInfant$ = this.toggleAdditionalInfantSubject.asObservable();

  calculate(): void {
    this.calculateEventSub.next();
  }

  setCalculateProgress(inProgress: boolean): void {
    this.calculateInProgressSub.next(inProgress);
  }

  setApplyChangeInProgress(inProgress: boolean): void {
    this.applyChangeInProgressSub.next(inProgress);
  }

  toggleAdditionalInfant(add: boolean): void {
    this.toggleAdditionalInfantSubject.next(add);
  }

  changeService(newUid: string, oldUid: string): void {
    const serviceUid = newUid ? newUid : oldUid;
    this.serviceChangedSubject.next(serviceUid);
  }

  changeHotel(newUid: string, oldUid: string): void {
    this.hotelChangedSubject.next({ newUid, oldUid });
  }

  changeFlights(newUid: string, oldUid: string): void {
    this.flightChangedSubject.next({ newUid, oldUid });
  }

  changeGdsFlights(uuid: string): void {
    this.flightGdsChangedSubject.next({ uuid });
  }
}
