import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewContainerRef,
} from '@angular/core';
import { CategoryClipItem } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../ui-components/alert-label/alert-label.component';
import { CheckboxRoundedComponent } from '../../../../../ui-components/checkbox-rounded/checkbox-rounded.component';
import { ImageViewerComponent } from '../../../../chat-timeline/components/image-viewer/image-viewer.component';
import { ImageItem } from '../../../../chat-timeline/interfaces/chat-timeline.interface';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';

interface VideoCategory {
  name: string;
  videos: ImageItem[];
}

@Component({
  selector: 'app-tour-content-videos',
  standalone: true,
  imports: [CheckboxRoundedComponent, AlertLabelComponent],
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideosComponent implements ContentCreatorItemComponent, OnInit, OnDestroy {
  @Input() public tourId: string;
  @Input() public tourContent: TourContent;
  @Input() public disableSelection = false;

  public categories: VideoCategory[] = [];
  public videos: ImageItem[];
  public promoVideos: ImageItem[] = [];
  public selectedVideos = new Set<ImageItem>();
  public videoWithVisiblePreview = signal<ImageItem | null>(null);
  public showVideoPreviewTimer: ReturnType<typeof setTimeout> | null = null;
  public firstInCategoryVideos = new Set<ImageItem>();
  public isGalleryOpen = false;

  protected readonly dangerAlertLabelType = AlertLabelType.danger;
  public galleryComponentRef: ComponentRef<ImageViewerComponent>;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const promoVideosWithCategory = [];
    const promoCategoryId = 8;
    const promoVideoUrls = new Set();
    if (this.tourContent.hotel.videos) {
      this.categories = this.tourContent.hotel.videos.map(
        (category: CategoryClipItem): VideoCategory => ({
          name: category.name,
          videos: category.clips.map(video => {
            const imageItem = {
              id: `${video.id}`,
              previewUrl: video.previewUrl,
              contentLink: video.url,
              text: category.name,
              isVideo: true,
            };
            const isPromo = video.isPromo || category.id === promoCategoryId;
            if (isPromo) {
              promoVideoUrls.add(video.url);
              if (category.id !== promoCategoryId) {
                promoVideosWithCategory.push(imageItem);
              }
            }
            return imageItem;
          }),
        }),
      );
    }
    const promoCategory = this.categories.find(category => category.name === 'Промо');
    // Нам надо удалить из промо категории видео, которые встречаются в других категориях,
    // чтобы не дублировать их в интерфейсе
    if (promoCategory) {
      promoCategory.videos = promoCategory.videos.filter(
        video => !promoVideosWithCategory.some(v => v.contentLink === video.contentLink),
      );
    }

    this.videos = this.categories.reduce((acc, category) => acc.concat(category.videos), [] as ImageItem[]);
    this.promoVideos = this.videos.filter(video => promoVideoUrls.has(video.contentLink));
    this.firstInCategoryVideos = new Set(this.categories.map(category => category.videos[0]));

    if (this.promoVideos.length) {
      // Если есть промо видео, то выделим их
      this.selectedVideos = new Set(this.promoVideos);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.galleryComponentRef?.destroy();
  }

  toggleVideoSelection(video: ImageItem): void {
    if (this.isVideoSelected(video)) {
      this.selectedVideos.delete(video);
    } else {
      this.selectedVideos.add(video);
    }
  }

  showVideoPreview(video: ImageItem): void {
    this.showVideoPreviewTimer = setTimeout(() => this.videoWithVisiblePreview.set(video), 200);
  }

  hideVideoPreview(): void {
    clearTimeout(this.showVideoPreviewTimer);
    this.videoWithVisiblePreview.set(null);
  }

  isVideoSelected(video: ImageItem): boolean {
    return this.selectedVideos.has(video);
  }

  isFirstInCategory(video: ImageItem): boolean {
    return this.firstInCategoryVideos.has(video);
  }

  isPromo(video: ImageItem): boolean {
    return this.promoVideos.includes(video);
  }

  selectVideo(isChecked: boolean, video: ImageItem): void {
    if (isChecked) {
      this.selectedVideos.add(video);
    } else {
      this.selectedVideos.delete(video);
    }
  }

  showGallery(video: ImageItem): void {
    const videoIndex = this.videos.indexOf(video);
    this.galleryComponentRef = this.viewContainerRef.createComponent(ImageViewerComponent);
    this.galleryComponentRef.instance.images = this.videos;
    this.galleryComponentRef.instance.currentImageIdx = videoIndex;
    this.galleryComponentRef.instance.selectedImages = this.selectedVideos;
    this.galleryComponentRef.instance.closed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.galleryComponentRef.destroy();
      this.isGalleryOpen = false;
    });
    this.galleryComponentRef.instance.imagesSelected.pipe(takeUntil(this.destroy$)).subscribe(images => {
      this.selectedVideos = images;
      this.cdr.detectChanges();
    });
    document.body.appendChild((this.galleryComponentRef.hostView as any).rootNodes[0] as HTMLElement);
    this.isGalleryOpen = true;
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    return of(
      Array.from(this.selectedVideos).map(video => ({
        component: this,
        message: {
          fileUrl: video.contentLink,
          contentParams: {
            hotelVideoId: Number(video.id),
          },
        },
      })),
    );
  }
}
