import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FlightNamePipe } from '../../pipes/flight-name.pipe';

@Component({
  selector: 'app-andromeda-claim-flights',
  templateUrl: './andromeda-claim-flights.component.html',
  styleUrls: ['./andromeda-claim-flights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, KeyValuePipe, FlightNamePipe],
})
export class AndromedaClaimFlightsComponent {
  form = input.required<FormGroup>();
  flightGroups = input.required<any>({});
}
