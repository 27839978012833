import { Pipe } from '@angular/core';

@Pipe({
  name: 'beachTypeLabel',
  standalone: true,
})
export class SearchHotelBeachTypePipe {
  private beachTypes = {
    sandy: 'Песок',
    pebble: 'Галька',
    sandy_pebble: 'Песок/галька',
  };

  transform(value: string): string {
    return this.beachTypes[value] || value;
  }
}
