import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  ElementRef,
  input,
  OnDestroy,
  output,
  viewChild,
} from '@angular/core';
import { PopupService } from '../../../../../shared/services/popup-service.service';
import { SearchTouristsRecalc } from '../../../search.model';
import { SearchRecalcModalComponent } from '../../recalc-modal/search-recalc-modal.component';

@Component({
  selector: 'app-search-hotel-recalc-btn',
  templateUrl: './search-hotel-recalc-btn.component.html',
  styleUrls: ['./search-hotel-recalc-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [PopupService],
})
export class SearchHotelRecalcBtnComponent implements OnDestroy {
  adults = input<number>();
  childAges = input<number[]>([]);
  splitRooms = input<boolean>(false);
  touristsLabel = computed(() => {
    let label = `${this.adults()} взр.`;
    const childCount = this.childAges()?.length;
    if (childCount) {
      label += ` ${childCount} дет.`;
    }

    return label;
  });
  modalRef = viewChild<ElementRef>('recalcModal');

  recalcTourists = output<SearchTouristsRecalc>();

  private modalComponentRef: ComponentRef<SearchRecalcModalComponent>;

  constructor(private readonly popupService: PopupService) {}

  recalc() {
    this.modalComponentRef = this.popupService.showPopup(
      SearchRecalcModalComponent,
      {},
      {
        anchorElement: this.modalRef(),
      },
    );
    this.modalComponentRef.setInput('initFormParams', {
      adults: this.adults(),
      childAges: this.childAges(),
      splitRooms: this.splitRooms(),
    });
    this.modalComponentRef.instance.onClose.subscribe(() => {
      this.popupService.closeAllModals();
    });
    this.modalComponentRef.instance.onSubmit.subscribe(tourists => {
      this.recalcTourists.emit(tourists);
    });
  }

  ngOnDestroy() {
    this.modalComponentRef?.destroy();
  }
}
