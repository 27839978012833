import { Directive, ElementRef, NgZone, OnDestroy, output } from '@angular/core';

@Directive({
  selector: '[appResizeObserver]',
  standalone: true,
})

// TODO: combine with ContentAdjustDirective
/**
 * Директива для отслеживания изменения размера элемента
 */
export class ResizeObserverDirective implements OnDestroy {
  sizeChange = output<{ width: number; height: number }>();
  widthChange = output<number>();
  heightChange = output<number>();

  private observer: ResizeObserver;

  constructor(private elementRef: ElementRef, private ngZone: NgZone) {
    const callback: ResizeObserverCallback = entries => {
      this.ngZone.run(() => {
        const width = entries[0].borderBoxSize[0].inlineSize;
        const height = entries[0].borderBoxSize[0].blockSize;
        this.sizeChange.emit({ width, height });
        this.widthChange.emit(width);
        this.heightChange.emit(height);
      });
    };

    this.observer = new ResizeObserver(callback);
    this.observer.observe(this.elementRef.nativeElement as Element);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
