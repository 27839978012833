<div class="andromeda-container" [formGroup]="form()">
  <h4>Проживание</h4>

  <table class="table">
    <thead>
    <tr>
      <th>Гостиница</th>
      <th>Город</th>
      <th>Номер</th>
      <th>Размещение</th>
      <th>Питание</th>
      <th>Период проживания</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{ hotel().name }}</td>
      <td>{{ hotel().town }}</td>
      <td>{{ hotel().room }}</td>
      <td>{{ hotel().htplace }}</td>
      <td>{{ hotel().meal }}</td>
      <td>{{ hotel().datebeg | date:'dd.MM' }} - {{ hotel().dateend | date:'dd.MM' }}</td>
    </tr>
      @if (hotel().partner) {
        <tr>
          <td colspan="6"><small>Принимающая компания: {{ hotel().partner }}</small></td>
        </tr>
      }
    </tbody>
    @if (note() || mealNote()) {
      <tfoot>
        @if (note()) {
          <tr class="warning">
            <td colspan="6"><small>{{ note() }}</small></td>
          </tr>
        }

      @if (mealNote()) {
        <tr class="info">
          <td colspan="6"><small class="text-muted">{{ mealNote() }}</small></td>
        </tr>
      }
      </tfoot>
    }
  </table>
</div>
