import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge, pairwise, startWith } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Worker } from '../../../../../../models';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../../ui-components/alert-label/alert-label.component';
import { SearchResultsResponseTour } from '../../../../services/search/websocket-tours.model';
import { AndromedaApiService } from '../../services/andromeda-api.service';
import { AndromedaUiService } from '../../services/andromeda-ui.service';
import {
  Claim,
  ClaimDocument,
  Group,
  Hotel,
  People,
  Service,
  Transport,
  ViewClaimResponse,
} from '../../services/andromeda.model';
import { AndromedaErrorComponent } from '../error/andromeda-error.component';
import { AndromedaClaimFlightGdsTariffComponent } from './components/flight-gds-tarif/andromeda-claim-flight-gds-tariff.component';
import { AndromedaClaimFlightsGdsComponent } from './components/flights-gds/andromeda-claim-flights-gds.component';
import { AndromedaClaimFlightsComponent } from './components/flights/andromeda-claim-flights.component';
import { AndromedaClaimHotelsCombiComponent } from './components/hotels-combi/andromeda-claim-hotels-combi.component';
import { AndromedaClaimHotelsComponent } from './components/hotels/andromeda-claim-hotels.component';
import { AndromedaClaimMessageComponent } from './components/message/andromeda-claim-message.component';
import { AndromedaClaimPriceComponent } from './components/price/andromeda-claim-price.component';
import { AndromedaClaimProviderComponent } from './components/provider/andromeda-claim-provider.component';
import { AndromedaClaimServicesComponent } from './components/services/andromeda-claim-services.component';
import { AndromedaClaimSpoComponent } from './components/spo/andromeda-claim-spo.component';
import { AndromedaClaimTouristsComponent } from './components/tourists/andromeda-claim-tourists.component';

const SERVICE_CATEGORY_KEY_TRANSFER = '1';
const SERVICE_CATEGORY_KEY_INSURANCE = '2';

@Component({
  selector: 'app-andromeda-state-show-claim',
  templateUrl: './andromeda-state-show-claim.component.html',
  styleUrl: './andromeda-state-show-claim.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AndromedaClaimProviderComponent,
    AndromedaClaimHotelsComponent,
    AndromedaClaimHotelsCombiComponent,
    AndromedaClaimFlightsComponent,
    AndromedaClaimFlightsGdsComponent,
    AndromedaClaimFlightsComponent,
    AndromedaClaimServicesComponent,
    AndromedaClaimTouristsComponent,
    AndromedaClaimSpoComponent,
    AndromedaClaimMessageComponent,
    AndromedaClaimFlightGdsTariffComponent,
    AndromedaClaimPriceComponent,
    AndromedaErrorComponent,
    AlertLabelComponent,
  ],
})
export class AndromedaStateShowClaimComponent implements OnInit {
  initTour = input.required<SearchResultsResponseTour>();
  selectedTour = input.required<SearchResultsResponseTour>();
  initClaim = input.required<Claim>();
  isGDS = input.required<boolean>();
  worker = input.required<Worker>();

  claim = signal<Claim>(null);
  groups = computed<Group[]>(() => {
    return this.claim().groups[0].group || [];
  });

  apiErrorResponse = signal<HttpErrorResponse>(null);
  showPrice = signal<boolean>(false);
  applyChangeInProgress = toSignal(this.uiService.applyChangeInProgress$);

  alertLabelType = AlertLabelType;

  calcForm: FormGroup;
  flightGroups: any;
  hotelGroups: any;

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly fb: FormBuilder,
    private readonly apiService: AndromedaApiService,
    private readonly uiService: AndromedaUiService,
  ) {}

  claimDocument = computed<ClaimDocument>(() => {
    return this.claim().claimDocument[0];
  });

  claimServices = computed<Service[]>(() => {
    return this.claimDocument().services[0]?.service || [];
  });

  hotels = computed<Hotel[]>(() => {
    return this.claimDocument().hotels[0]?.hotel || [];
  });

  transferServices = computed<Service[]>(() => {
    return this.getServicesByType(this.claimServices(), 'stTransfer', SERVICE_CATEGORY_KEY_TRANSFER);
  });

  insuranceServices = computed<Service[]>(() => {
    return this.getServicesByType(this.claimServices(), 'stInsurance', SERVICE_CATEGORY_KEY_INSURANCE);
  });

  otherServices = computed<Service[]>(() => {
    const services = this.claimServices();
    const additionalServices = this.additionalServices();
    const allServices = services.concat(additionalServices);

    return allServices.filter(service => {
      if (
        service.hidden === 'true' ||
        ['stTransfer', 'stInsurance'].includes(service.type) ||
        [SERVICE_CATEGORY_KEY_TRANSFER, SERVICE_CATEGORY_KEY_INSURANCE].includes(service.servicecategoryKey)
      ) {
        return false;
      }

      return true;
    });
  });

  transports = computed<Transport[]>(() => {
    return this.claimDocument().transports[0]?.transport || [];
  });

  transportsVariants = computed<Transport[]>(() => {
    if (
      this.claim().hasOwnProperty('variants') &&
      Array.isArray(this.claim().variants) &&
      this.claim().variants[0].hasOwnProperty('transports') &&
      this.claim().variants[0].transports !== null &&
      this.claim().variants[0].transports[0] !== null &&
      this.claim().variants[0].transports[0].hasOwnProperty('transport') &&
      this.claim().variants[0].transports[0].transport !== null
    ) {
      return this.claim().variants[0].transports[0].transport || [];
    }

    return [];
  });

  additionalServices = computed<Service[]>(() => {
    return this.claim().variants[0]?.services[0]?.service || [];
  });

  get servicesControl(): FormGroup {
    return this.calcForm.get('services') as FormGroup;
  }

  get hotelsControl(): FormGroup {
    return this.calcForm.get('hotels') as FormGroup;
  }

  get flightsControl(): FormGroup {
    return this.calcForm.get('flights') as FormGroup;
  }

  hotelsVariants = computed<Hotel[]>(() => {
    if (
      this.claim().hasOwnProperty('variants') &&
      Array.isArray(this.claim().variants) &&
      this.claim().variants[0].hasOwnProperty('hotels') &&
      this.claim().variants[0].hotels !== null &&
      this.claim().variants[0].hotels[0] !== null &&
      this.claim().variants[0].hotels[0].hasOwnProperty('hotel') &&
      this.claim().variants[0].hotels[0].hotel !== null
    ) {
      return this.claim().variants[0].hotels[0].hotel || [];
    }

    return [];
  });

  tourists = computed<People[]>(() => {
    return this.claimDocument().peoples[0].people || [];
  });

  availableAdditionalInfant = computed<boolean>(() => {
    return this.claimDocument()?.freeinfant === '1';
  });

  additionalInfantChecked = computed<boolean>(() => {
    return this.claimDocument()?.freeinfant_checked === '1';
  });

  spoMessage = computed<string>(() => {
    return this.claim()?.notes?.[0]?.spogMessage?.[0]?._ || '';
  });

  message = computed<string>(() => {
    return this.claimDocument().messages?.[0]?.message?.[0]._ || '';
  });

  gdsTransport = computed<string>(() => {
    const allTariff = this.claimDocument().gdsTransport?.[0]?.message || [];
    const tariff = allTariff.length > 0 ? allTariff[allTariff.length - 1]._ : '';

    return tariff.replace(/\n/g, '<br>').replace(/\t/, '&nbsp;&nbsp;&nbsp;&nbsp;');
  });

  ngOnInit(): void {
    this.claim.set(this.initClaim());

    this.createForm();
    this.populateForm();
    this.subscribeFormHandlers();
    this.subscribeUiHandlers();

    this.uiService.calculate();
  }

  private subscribeUiHandlers(): void {
    this.uiService.calculateEvent$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.apiErrorResponse.set(null);
          this.uiService.setCalculateProgress(true);
        }),
        switchMap(() => this.apiService.calculateClaim(this.worker(), this.selectedTour(), this.claim())),
      )
      .subscribe({
        next: viewClaim => {
          this.updateFormServicesControls(viewClaim);

          this.claim.set(viewClaim.rawClaim);
          this.uiService.setCalculateProgress(false);
          this.showPrice.set(true);
        },
        error: error => {
          this.apiErrorResponse.set(error);
          this.uiService.setCalculateProgress(false);
        },
      });

    const serviceChanged$ = this.uiService.serviceChanged$.pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.showPrice.set(false);
        this.uiService.setApplyChangeInProgress(true);
      }),
      switchMap(serviceUid =>
        this.apiService.changeService(this.worker(), this.selectedTour(), this.claim(), serviceUid),
      ),
    );

    const hotelChanged$ = this.uiService.hotelChanged$.pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.showPrice.set(false);
        this.uiService.setApplyChangeInProgress(true);
      }),
      switchMap(({ newUid, oldUid }) =>
        this.apiService.changeHotel(this.worker(), this.selectedTour(), this.claim(), newUid, oldUid),
      ),
    );

    const flightChanged$ = this.uiService.flightChanged$.pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.showPrice.set(false);
        this.uiService.setApplyChangeInProgress(true);
      }),
      switchMap(({ newUid, oldUid }) =>
        this.apiService.changeFlight(this.worker(), this.selectedTour(), this.claim(), newUid, oldUid),
      ),
    );

    const toggleAdditionalInfant$ = this.uiService.toggleAdditionalInfant$.pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(() => {
        this.showPrice.set(false);
        this.uiService.setApplyChangeInProgress(true);
      }),
      switchMap(() =>
        this.apiService.toggleAdditionalInfant(this.worker(), this.selectedTour(), this.claim()),
      ),
    );

    merge(serviceChanged$, hotelChanged$, flightChanged$, toggleAdditionalInfant$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: viewClaim => {
          this.updateFormServicesControls(viewClaim);
          this.claim.set(viewClaim.rawClaim);
          this.uiService.setApplyChangeInProgress(false);
        },
        error: error => {
          console.error(error);
        },
      });
  }

  private updateFormServicesControls(viewClaim: ViewClaimResponse): void {
    // Андромеда может поменять id услуг, поэтому нужно прокинуть новые
    const services = viewClaim.rawClaim.claimDocument[0].services[0]?.service || [];
    const servicesVariants = viewClaim.rawClaim.variants[0].services[0]?.service || [];
    this.populateFormServices(services, servicesVariants);
  }

  private createForm(): void {
    this.calcForm = this.fb.group({
      tourId: ['', Validators.required],
      hotels: this.fb.group({}),
      flights: this.fb.group({}),
      services: this.fb.group({}),
    });
  }

  private populateForm(): void {
    const variants = this.claim().variants[0];
    const services = this.claimServices();
    const serviceVariants = variants?.services[0]?.service || [];

    this.calcForm.patchValue({ tourId: this.claimDocument().andromedaCatalogKey });

    this.hotels().forEach(hotel => {
      this.hotelsControl.addControl(`group${hotel.groupId}`, new FormControl(hotel.uid));
    });

    this.transports().forEach(flight => {
      this.flightsControl.addControl(`group${flight.groupId}`, new FormControl(flight.uid));
    });

    this.populateFormServices(services, serviceVariants);

    if (this.isGDS()) {
      this.createGdsFlightsGroups();
    } else {
      this.createFlightsGroups();
    }

    this.createHotelsGroups();
  }

  private populateFormServices(services: Service[], servicesVariants: Service[]): void {
    services
      .filter(service => service.hidden === 'false')
      .forEach(service => {
        this.servicesControl.addControl(service.uid, new FormControl(true));
      });

    if (servicesVariants.length) {
      servicesVariants
        .filter(service => service.hidden === 'false')
        .forEach(service => {
          this.servicesControl.addControl(`${service.uid}`, new FormControl(false));
        });
    }
  }

  private subscribeFormHandlers(): void {
    const getDifference = (a, b) =>
      Object.entries(b)
        .filter(([key, val]) => a[key] !== val && key in a)
        .reduce((a, [key, v]) => ({ ...a, [key]: v }), {});

    this.calcForm
      .get('flights')
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.calcForm.get('flights').value),
        pairwise(),
      )
      .subscribe(([prev, next]: [any, any]) => {
        if (this.isGDS()) {
          this.uiService.changeGdsFlights(next.group);
        } else {
          const differenceObject = getDifference(prev, next);
          const [groupId, serviceUid] = Object.entries<string>(differenceObject)[0];
          const prevServiceUid = prev[groupId] || '';

          this.uiService.changeFlights(serviceUid, prevServiceUid);
        }

        this.showPrice.set(false);
      });

    this.calcForm
      .get('hotels')
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.calcForm.get('hotels').value),
        pairwise(),
      )
      .subscribe(([prev, next]: [any, any]) => {
        const differenceObject = getDifference(prev, next);
        const [groupId, serviceUid] = Object.entries<string>(differenceObject)[0];
        const prevServiceUid = prev[groupId] || '';

        this.uiService.changeHotel(serviceUid, prevServiceUid);

        this.showPrice.set(false);
      });
  }

  private getServicesByType(
    allServices: Service[],
    type: string,
    serviceCategoryKey: string | null,
  ): Service[] {
    const services = allServices.filter(
      service =>
        service.hidden === 'false' &&
        (service.type === type || service?.servicecategoryKey === serviceCategoryKey),
    );

    const additionalServices = this.additionalServices().filter(service => {
      if (service.hidden === 'false' && service.type === type) {
        const currentService = services.find(currentService => currentService.uid === service.uid);
        return currentService === undefined;
      }
      return false;
    });

    return services.concat(additionalServices);
  }

  private createGdsFlightsGroups(): void {
    const groups = {};

    const flights = this.transports().concat(this.transportsVariants());
    flights.forEach(flight => {
      const key = `${flight.externalOfferId}`;
      if (!groups.hasOwnProperty(key)) {
        groups[key] = [];
      }
      const details = [];
      flight.details[0].detail.forEach(detail => {
        detail.uid = flight.uid;

        details.push(detail);
      });

      groups[key] = groups[key].concat(details);
    });

    if (this.transports().length) {
      this.flightsControl.addControl(`group`, new FormControl(this.transports()[0].uid));
    } else if (Object.keys(groups).length === 1) {
      this.flightsControl.addControl(`group`, new FormControl());
    } else {
      this.flightsControl.addControl(`group`, new FormControl());
    }

    this.flightGroups = groups;
  }

  private createFlightsGroups() {
    const flights = this.transports().concat(this.transportsVariants());

    const flightsUid = [];
    const groups = {};
    flights.forEach(flight => {
      const documentGroup = this.groups().find(group => group.id === flight.groupId);

      if (!groups.hasOwnProperty(flight.groupId)) {
        const note = flight?.note?.[0]?._ || '';

        groups[flight.groupId] = {
          id: documentGroup.id,
          name: documentGroup.description,
          required: documentGroup.required === 'true',
          note: note.replace(/\n/g, '<br>').replace(/\t/, '    '),
          flights: [],
        };

        this.flightsControl.addControl(`group${flight.groupId}`, new FormControl(flight.uid));
      }

      // В transports и transportsVariants могут быть дубли, поэтому нужно проверять
      if (flightsUid.indexOf(flight.uid) === -1) {
        flightsUid.push(flight.uid);

        groups[flight.groupId].flights.push(flight);
      }
    });

    this.flightGroups = groups;
  }

  private createHotelsGroups(): void {
    const hotels = this.hotels().concat(this.hotelsVariants());

    const hotelUids = [];
    const groups = {};
    hotels.forEach(hotel => {
      const documentGroup = this.groups().find(group => group.id === hotel.groupId);
      if (!groups.hasOwnProperty(hotel.groupId)) {
        groups[hotel.groupId] = {
          id: documentGroup.id,
          name: documentGroup.description,
          required: documentGroup.required === 'true',
          databeg: hotel.datebeg,
          dateend: hotel.dateend,
          hotels: [],
        };

        this.hotelsControl.addControl(`group${hotel.groupId}`, new FormControl(hotel.uid));
      }

      if (hotelUids.indexOf(hotel.uid) === -1) {
        hotelUids.push(hotel.uid);

        groups[hotel.groupId].hotels.push(hotel);
      }
    });
    this.hotelGroups = groups;
  }
}
