import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { PriceCurrencyPipe } from '../../../../../pipes/price-currency.pipe';

@Component({
  selector: 'app-andromeda-state-show-tour-price-diff',
  templateUrl: './andromeda-state-show-tour-price-diff.component.html',
  styleUrl: './andromeda-state-show-tour-price-diff.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe, PriceCurrencyPipe],
})
export class AndromedaStateShowTourPriceDiffComponent {
  oldPrice = input.required<number>();
  newPrice = input.required<number>();
  currency = input.required<string>();

  diff = computed<number>(() => {
    return this.newPrice() - this.oldPrice();
  });
}
