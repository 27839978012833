import { DatePipe, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';

import { toSignal } from '@angular/core/rxjs-interop';
import { HotelManagerComment } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of } from 'rxjs';
import { PluralizePipe } from '../../../../../helpers/pipes/plural/pluralize.pipe';
import { ResizeObserverDirective } from '../../../../../shared/directives/resize-observer.directive';
import { TextSelectionDirective } from '../../../../../shared/directives/text-selection.directive';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../ui-components/alert-label/alert-label.component';
import { SpeechBubbleComponent } from '../../../../../ui-components/speech-bubble/speech-bubble.component';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';
import { ContentCreatorSizesService } from '../../../services/content-creator-sizes.service';

@Component({
  selector: 'app-tour-content-manager-comments',
  standalone: true,
  imports: [
    PluralizePipe,
    SpeechBubbleComponent,
    ResizeObserverDirective,
    TextSelectionDirective,
    AlertLabelComponent,
    NgStyle,
    DatePipe,
  ],
  templateUrl: './manager-comments.component.html',
  styleUrls: ['./manager-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerCommentsComponent implements ContentCreatorItemComponent, OnInit {
  @Input() tourContent: TourContent;
  @Input() tourId: string;
  @Input() appTextSelectionEnabled = true;
  @Output() insertSingleMessageText = new EventEmitter<string>();
  lastSelectedText = signal<string>('');

  public comments: HotelManagerComment[];

  // Значения для расчета высоты блока с отзывами
  private contentCreatorBaseReservedHeight = toSignal(this.contentCreatorSizesService.reservedHeight$);
  public countInfoHeight = signal<number>(0);
  public contentCreatorReservedHeight = computed(
    () => this.contentCreatorBaseReservedHeight() + this.countInfoHeight(),
  );

  public errorMessage = signal<string>('');
  protected readonly dangerAlertLabelType = AlertLabelType.danger;

  constructor(private contentCreatorSizesService: ContentCreatorSizesService) {}

  ngOnInit(): void {
    this.comments = this.tourContent?.hotel?.managerComments;
    if (!this.comments || !this.comments.length) {
      this.errorMessage.set('Комментарии менеджеров не найдены');
    }
  }

  addTextToMessage(text: string) {
    this.insertSingleMessageText.emit(`\n${text}`);
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    return of([] as ContentCreatorItemMessage[]);
  }
}
